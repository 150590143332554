import { Box, Divider } from '@mui/material';
import AppInput from 'Atomic/atoms/Input/AppInput';
import AppText from 'Atomic/atoms/Text/AppText';
import AppColorPicker from 'Atomic/organisms/AppColorPicker/AppColorPicker';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import React, { useState } from 'react';



const BackgroundColorSetting = () => {

	const { translatePlaceholder } = useTranslations();

	const { currentSlide } = useSlides();

	const { setSlideSetting } = useSlideActions();

	const [backgroundColorPlaceholder, setBackgroundColorPlaceholder] = useState<string>(currentSlide?.backgroundColor || "");

	const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);

	const isValidColor = (value: string) => {

		const s = new Option().style;

		s.color = value;

		return s.color !== '';
	}

	const handleChangeBackgroundColor = (newValue: string) => {

		setBackgroundColorPlaceholder(newValue);

		if (isValidColor(newValue)) {

			setColorPickerOpen(false);
			setSlideSetting("backgroundColor", newValue, currentSlide.id)
		}
	}

	return (
		<Box py={2}>
			<Divider />
			<AppText
				py={2}
				fontSize={14}
				fontWeight={600}
				color="#000"
			>
				{translatePlaceholder("BACKGROUND_COLOR")}
			</AppText>
			<Box>
				<AppInput
					startAdornment={(
						<Box
							onClick={() => setColorPickerOpen(!colorPickerOpen)}
							style={{
								cursor: "pointer",
								height: 24,
								width: 24,
								marginLeft: -12,
								backgroundImage: `linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%)`,
								backgroundSize: `10px 10px`,
								backgroundPosition: `0 0, 5px 5px`,
								borderRadius: 48,
							}}
						>
							<Box
								style={{
									borderRadius: 24,
									height: 24,
									width: 24,
									backgroundColor: backgroundColorPlaceholder
								}}
							/>
						</Box>
					)}
					value={backgroundColorPlaceholder}
					onChange={(e) => handleChangeBackgroundColor(e.target.value)}
					onClick={() => setColorPickerOpen(!colorPickerOpen)}
					placeholder={translatePlaceholder("BACKGROUND_COLOR_PLACEHOLDER")}
				/>
			</Box>
			<Divider />
			{ colorPickerOpen &&
				<>
					<Box pb={2} />
					<AppColorPicker
						value={backgroundColorPlaceholder}
						setValue={(hexValue) => handleChangeBackgroundColor(hexValue)}
					/>
				</>
			}
		</Box>
	)

}

export default BackgroundColorSetting;