import { Box, Divider, Slider } from '@mui/material';
import AppText from 'Atomic/atoms/Text/AppText';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import React, { useMemo } from 'react';
import { classes } from './style.css';

const ImageOpacitySlider = () => {

    const { currentSlide } = useSlides();

    const { translatePlaceholder } = useTranslations();

    const { setSlideSetting } = useSlideActions();

    const opacityValue = useMemo(() => {

        return Number(currentSlide.backgroundOpacity || 0) * 100;

    }, [currentSlide.backgroundOpacity]);

    const handleSetBackgroundOpacity = (value: number) => {

        const newOpacityValue = (value as number) * .01;

        setSlideSetting('backgroundOpacity', String(newOpacityValue), currentSlide.id)
    }

    return (
        <Box py={2}>
            <Divider />
            <AppText
                pt={2}
                pb={1}
                color="#000"
                fontWeight={600}
                fontSize={14}
            >
                {translatePlaceholder("BACKGROUND_IMAGE_OPACITY")} ({opacityValue}%)
            </AppText>
            <Box display="flex" alignItems="center" pb={2}>
                <Box pr={2}>
                    0%
                </Box>
                <Slider
                    classes={{
                        root: classes.sliderRoot,
                    }}
                    marks
                    min={0}
                    step={10}
                    max={100}
                    onChange={(_, newValue) => handleSetBackgroundOpacity(newValue as number)}
                    value={opacityValue}
                />
                <Box pl={1.5}>
                    100%
                </Box>
            </Box>

            <Divider />
        </Box>
    );
};

export default ImageOpacitySlider;