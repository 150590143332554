import { ChevronRight } from '@mui/icons-material';
import { Box, Chip, Divider, Drawer, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import AppInput from 'Atomic/atoms/Input/AppInput';
import AppText from 'Atomic/atoms/Text/AppText';
import { TableCellData, TableCellDataType, TableCellType } from 'Atomic/organisms/AppDynamicTable/AppDynamicTable';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import PageLoader from 'Components/common/util/page_loader/PageLoader';
import { useAppRouting } from 'Hooks/useAppRouting';
import { useDataConnector } from 'Hooks/useDataConnections';
import { useDebouncedEffect } from 'Hooks/useDebouncedEffect';
import { useLoading } from 'Hooks/useLoading';
import useSlideActions from 'Hooks/useSlideActions';
import { useTranslations } from 'Hooks/useTranslations';
import { generateString } from 'Scripts/keyHelper';
import { addOrRemoveFromListState } from 'Scripts/stateHelper';
import { DataConnection, Dimension, DimensionHeader, Metric, MetricHeader, ReportData, ReportRowData } from 'Types/dataConnectorTypes';
import { Search } from 'iconoir-react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { DragDropContext, DragStart, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { AppDyanmicTableBoundaries } from '../../../atomic/organisms/AppDynamicTable/AppDynamicTable';

/**
 * Max allowed table columns minus 1 for the table headers
 */
const MAX_ALLOWED_METRICS_OR_DIMENSIONS = (AppDyanmicTableBoundaries.MaxColumns - 1);

enum DataConnectorLoadStates {
    DataConnectorLoading,
    DimensionsAndMetricsLoading,
    ReportLoading,
    AccountsListLoading,
}

enum DroppableEntries {
    Metrics = "metrics",
    SelectedMetrics = "selectedMetrics",
    Dimensions = "dimensions",
    SelectedDimensions = "selectedDimensions",
}

enum MetaDataType {
    Metric = "metric",
    Dimension = "dimension",
}

enum SortDirection {
    Ascending = "ascending",
    Descending = "descending",
}

interface DropEntry {
    addCallback: (data: any) => void;
    removeCallback: (data: any) => void;
    data: Metric[] | Dimension[];
    limit?: number;
    type: MetaDataType;
}

export const DataConnectorComponent = () => {

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const { currentSlideKey } = useAppRouting();

    const { setSlideTable } = useSlideActions();

    const { enqueueSnackbar } = useSnackbar();

    const { translatePlaceholder } = useTranslations();

    const {
        startLoading,
        stopLoading,
        isLoading,
    } = useLoading([
        DataConnectorLoadStates.DataConnectorLoading,
        DataConnectorLoadStates.DimensionsAndMetricsLoading,
        DataConnectorLoadStates.ReportLoading,
    ]);

    const [dataConnections, setDataConnections] = useState<DataConnection[]>([]);

    const [selectedDataConnection, setSelectedDataConnection] = useState<DataConnection | null>(null);

    const [selectedDimensions, setSelectedDimensions] = useState<Dimension[]>([]);

    const [selectedMetrics, setSelectedMetrics] = useState<Metric[]>([]);

    const [dimensions, setDimensions] = useState<Dimension[]>([]);

    const [metrics, setMetrics] = useState<Metric[]>([]);

    const [report, setReport] = useState<ReportData | null>(null);

    const [searchQuery, setSearchQuery] = useState<string>("");

    const [placeholderSearchQuery, setPlaceholderSearchQuery] = useState<string>("");

    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Ascending);

    const fetchMetricsAndDimensions = async (referenceId: string, selectedMetricApiNames: Array<string>, selectedDimensionApiNames: Array<string>) => {

        startLoading(DataConnectorLoadStates.DimensionsAndMetricsLoading);

        try {

            const { metrics, dimensions } = await getDimensionsAndMetrics(
                referenceId,
                selectedMetricApiNames,
                selectedDimensionApiNames
            );
            /** Set the metrics/dimensions but filter them based on the current selected metrics/dimensions */
            setMetrics(metrics.filter((m: Metric) => !selectedMetricApiNames.includes(m.apiName)));

            setSelectedMetrics(metrics.filter((m: Metric) => selectedMetricApiNames.includes(m.apiName)));

            setDimensions(dimensions.filter((d: Dimension) => !selectedDimensionApiNames.includes(d.apiName)));

            setSelectedDimensions(dimensions.filter((d: Dimension) => selectedDimensionApiNames.includes(d.apiName)));

        } catch (error) {

            enqueueSnackbar(error);

        } finally {

            stopLoading(DataConnectorLoadStates.DimensionsAndMetricsLoading);
        }
    }

    const fetchReport = async (referenceId: string) => {

        startLoading(DataConnectorLoadStates.ReportLoading);

        try {

            setReport(await getReport(selectedDimensions, selectedMetrics, referenceId));

        } catch (error) {

            enqueueSnackbar(error);

        } finally {

            stopLoading(DataConnectorLoadStates.ReportLoading);
        }
    }

    const fetchReportMetadata = async (slideId: number) => {

        startLoading(DataConnectorLoadStates.DimensionsAndMetricsLoading);

        try {

            const data = await getReportMetadata(slideId);

            if (Boolean(data)) {

                const dataConnection = dataConnections.find((connection) => connection.referenceId === data.referenceId);

                await fetchMetricsAndDimensions(
                    data.referenceId,
                    data.metrics.split(","),
                    data.dimensions.split(",")
                );

                if (dataConnection) {
                    /** Pre-select the data connection for the current slide if there is one available */
                    setSelectedDataConnection(dataConnection);
                }
            }

        } catch (error) {

            enqueueSnackbar(error);

        } finally {

            stopLoading(DataConnectorLoadStates.DimensionsAndMetricsLoading);
        }
    }

    const fetchDataConnections = async () => {

        startLoading(DataConnectorLoadStates.DataConnectorLoading);

        try {

            setDataConnections(await getDataConnections());

        } catch (error) {

            enqueueSnackbar(error);
        }

        stopLoading(DataConnectorLoadStates.DataConnectorLoading);
    }

    const setReportAsTableData = () => {

        const tableCells: Array<Array<Partial<TableCellData>>> = [];

        (tableData || []).forEach((row, rowIndex) => {

            const tableCellRow: Array<Partial<TableCellData>> = [];

            const isTableHeader = (rowIndex === 0);

            row.forEach((cell) => {

                tableCellRow.push({
                    id: generateString(32),
                    content: cell,
                    type: TableCellType.Text,
                    cellType: isTableHeader ? TableCellDataType.Heading : TableCellDataType.Default,
                    jss: '{}'
                });
            })

            tableCells.push(tableCellRow);
        });

        setSlideTable(tableCells as Array<Array<TableCellData>>, currentSlideKey);
    }

    /**
     * Clear all the states
     */
    const clearStates = () => {

        setDataConnections([]);

        setSelectedDataConnection(null);

        setSelectedDimensions([]);

        setSelectedMetrics([]);

        setMetrics([]);

        setDimensions([]);

        setReport(null);

        setSearchQuery("");
    }

    const handleAddDataConnectionMetadata = async () => {

        if(!selectedDimensions.length || !selectedMetrics.length || !selectedDataConnection) {

            return;
        }

        startLoading(DataConnectorLoadStates.ReportLoading);

        try {

            setReportAsTableData();

            await addReportMetadata(
                selectedDimensions,
                selectedMetrics,
                currentSlideKey,
                selectedDataConnection.referenceId,
            );

        } catch (error) {

            enqueueSnackbar(error);
        }

        stopLoading(DataConnectorLoadStates.ReportLoading);
    }

    const dimensionUiNameByApiName = useMemo(() => {

        return [
            ...dimensions,
            ...selectedDimensions,
        ].reduce((dimensionUiNameMap, dimension) => {

            dimensionUiNameMap[dimension.apiName] = dimension.uiName;

            return dimensionUiNameMap;

        }, {} as Record<string, string>);

    }, [
        dimensions,
        selectedDimensions,
    ]);

    const metricUiNamesByApiName = useMemo(() => {

        return [
            ...metrics,
            ...selectedMetrics,
        ].reduce((metricUiNameMap, metric) => {

            metricUiNameMap[metric.apiName] = metric.uiName;

            return metricUiNameMap;

        }, {} as Record<string, string>);

    }, [
        metrics,
        selectedMetrics,
    ]);

    /**
     * Format the data every time the report changes
     */
    const tableData = useMemo(() => {

        if (isEmpty(report)) {

            return null;
        }

        let tableData: Array<Array<string>> = [];

        const { dimensionHeaders, metricHeaders, rows } = report as ReportData;

        const headers: Array<string> = [
            ...dimensionHeaders.map((dimension: DimensionHeader) => (
                dimensionUiNameByApiName[dimension.name] || dimension.name
            )),
            ...metricHeaders.map((metric: MetricHeader) => (
                metricUiNamesByApiName[metric.name] || metric.name
            )),
        ];

        tableData.push(headers);

        rows.forEach((row: ReportRowData) => {

            const { metricValues, dimensionValues } = row;

            const dataRow: Array<string> = [
                ...dimensionValues.map(dimension => dimension.value),
                ...metricValues.map(metric => metric.value),
            ];

            tableData.push(dataRow);

        });

        return tableData;

    }, [report])

    /**
     * Whenever the table data is updated/set,
     * add the data to the current slides table
     */
    useEffect(() => {

        if (!tableData) {

            return;
        }

        handleAddDataConnectionMetadata();

    }, [tableData]);

    const {
        getReport,
        getReportMetadata,
        getDataConnections,
        getDimensionsAndMetrics,
        addReportMetadata,
    } = useDataConnector();

    /**
     * Fetch the data connections once the dialog opens
     * and clear the state once it closes
     */
    useLayoutEffect(() => {

        if (drawerOpen) {

            fetchDataConnections();
        }

        if (!drawerOpen) {

            clearStates();
        }

    }, [drawerOpen])

    /**
     * Fetch the report metadata for the current slide (if it exists)
     *
     * Note: this needs the dimensions/metrics to be loaded or else it can't build references to these metrics/dimensions
     * as they're stored in CSV format
     *
     * e.g: "7DayActiveUsers,1DayActiveUsers," etc
     */
    useEffect(() => {

        if (Boolean(currentSlideKey) && drawerOpen && Boolean(dataConnections.length)) {

            fetchReportMetadata(currentSlideKey);
        }

    }, [
        dataConnections.length,
        currentSlideKey,
        drawerOpen
    ]);

    useEffect(() => {

        if (!(
            selectedDataConnection &&
            selectedMetrics.length &&
            selectedDimensions.length
        )) {

            return;
        }

        fetchReport(selectedDataConnection.referenceId);

    }, [
        selectedDataConnection,
        selectedMetrics,
        selectedMetrics.length,
        selectedDimensions,
        selectedDimensions.length,
    ])

    /**
     * Fetch the metrics/dimensions for the selected data connection
     * whenever it changes
     */
    useLayoutEffect(() => {

        if (Boolean(selectedDataConnection)) {

            const { referenceId } = selectedDataConnection as DataConnection;

            fetchMetricsAndDimensions(
                referenceId,
                selectedMetrics.map(m => m.apiName),
                selectedDimensions.map(m => m.apiName),
            );
        }

    }, [
        selectedDataConnection,
        selectedMetrics.length,
        selectedDimensions.length,
    ]);

    const filteredDimensions = useMemo(() => {

        return dimensions.filter((dimension) => {

            return (
                `${dimension.apiName}`.includes(searchQuery) ||
                `${dimension.category}`.includes(searchQuery) ||
                `${dimension.description}`.includes(searchQuery) ||
                `${dimension.uiName}`.includes(searchQuery)
            );
        });

    }, [
        searchQuery,
        dimensions,
    ]);

    const filteredMetrics = useMemo(() => {

        return metrics.filter((metric) => {

            return (
                `${metric.apiName}`.includes(searchQuery) ||
                `${metric.category}`.includes(searchQuery) ||
                `${metric.description}`.includes(searchQuery) ||
                `${metric.uiName}`.includes(searchQuery)
            );
        });

    }, [
        searchQuery,
        metrics,
    ]);

    useDebouncedEffect(() => {

        setSearchQuery(placeholderSearchQuery);

    }, [placeholderSearchQuery], 250);

    const onDragEnd = (e: DropResult) => {

        setDropHighlight(null);

        const dropMap: Record<DroppableEntries, DropEntry> = {
            [DroppableEntries.Metrics]: {
                addCallback: setMetrics,
                removeCallback: setSelectedMetrics,
                type: MetaDataType.Metric,
                data: selectedMetrics,
                limit: MAX_ALLOWED_METRICS_OR_DIMENSIONS,
            },
            [DroppableEntries.SelectedMetrics]: {
                addCallback: setSelectedMetrics,
                removeCallback: setMetrics,
                type: MetaDataType.Metric,
                data: metrics,
            },
            [DroppableEntries.Dimensions]: {
                addCallback: setDimensions,
                removeCallback: setSelectedDimensions,
                type: MetaDataType.Dimension,
                data: selectedDimensions,
                limit: MAX_ALLOWED_METRICS_OR_DIMENSIONS,
            },
            [DroppableEntries.SelectedDimensions]: {
                addCallback: setSelectedDimensions,
                removeCallback: setDimensions,
                type: MetaDataType.Dimension,
                data: dimensions,
            },
        }

        if (!e.destination) {

            return;
        }

        const dropCallback = dropMap[e.destination.droppableId as DroppableEntries];

        if (!dropCallback) {

            return;
        }

        /** Swap order since the item was dropped on the same Droppable component*/
        if (e.destination.droppableId === e.source.droppableId) {

            const destinationIndex = e.destination.index;

            const sourceIndex = e.source.index;

            dropCallback.addCallback((currentValues: Metric[] | Dimension[]) => {

                const newValues = [...currentValues];

                const [itemToReposition] = newValues.splice(sourceIndex, 1);

                newValues.splice(destinationIndex, 0, itemToReposition);

                return newValues;
            });

            return;
        }

        const metaDataItem = dropCallback.data.find((metadata) => metadata.apiName === e.draggableId);

        if (!metaDataItem) {

            return;
        }

        if (Boolean(dropCallback.limit) && dropCallback.data.length >= (dropCallback.limit as number)) {

            enqueueSnackbar(translatePlaceholder("MAXIMUM_FIELDS_SELECTED"), {
                variant: 'warning' as never,
            })

            return;
        }

        addOrRemoveFromListState(metaDataItem, 'apiName', dropCallback.addCallback, e.destination.index);

        addOrRemoveFromListState(metaDataItem, 'apiName', dropCallback.removeCallback, e.destination.index);
    }

    const [
        isDataConnectorLoading,
        isMetadataLoading,
        isReportLoading
    ] = ([
        isLoading(DataConnectorLoadStates.DataConnectorLoading),
        isLoading(DataConnectorLoadStates.DimensionsAndMetricsLoading),
        isLoading(DataConnectorLoadStates.ReportLoading),
    ]);

    const [dropHighlight, setDropHighlight] = useState<DroppableEntries | null>(null);

    const onDragStart = (e: DragStart) => {

        setDropHighlight(e.source.droppableId as DroppableEntries);
    }

    return (
        <>
            <Box py={2}>
                <AppButton
                    onClick={() => setDrawerOpen(true)}
                    fullWidth
                    as={AppButtonVariant.Primary}>
                    {translatePlaceholder("DATACONNECTOR_SETTINGS")}
                </AppButton>
            </Box>
            <Divider />
            <Drawer
                open={drawerOpen}
                anchor='right'
            >
                <DragDropContext
                    onDragStart={onDragStart}
                    onDragEnd={result => onDragEnd(result)}>
                    <Box
                        overflow="hidden"
                        display="flex"
                        flexDirection="row"
                        height="100%">
                        <Box
                            p={2}
                            width={32 * 8}
                            style={{
                                background: '#E9E8EA',
                                borderRight: `1px solid #D5D2DB`,
                                overflowY: 'auto',
                            }}>
                            <Box
                                py={2}
                                pb={6}>
                                <IconButton onClick={() => setDrawerOpen(false)}>
                                    <ChevronRight />
                                </IconButton>
                            </Box>
                            <Box py={2}>
                                <AppText
                                    py={2}
                                    fontSize={16}
                                    fontWeight={700}
                                    color="primary">
                                    {translatePlaceholder("EXPLORER")}
                                </AppText>
                                <Divider />

                                <AppText
                                    py={2}
                                    fontSize={14}
                                    fontWeight={700}
                                    color="#90809D">
                                    {translatePlaceholder("DATA_SOURCES")}
                                </AppText>
                                {isDataConnectorLoading && (
                                    <Box mt={-1} pb={1}>
                                        <PageLoader />
                                    </Box>
                                )}
                                {dataConnections.map((connection) => {

                                    const isSelectedDataConnection = (connection.id === selectedDataConnection?.id);

                                    return (
                                        <Box
                                            key={connection.id}
                                            mb={1}
                                            onClick={() => setSelectedDataConnection(connection)}
                                            style={{
                                                transition: "250ms all",
                                                background: isSelectedDataConnection ? `#429EFF` : `#D5D2DB`,
                                                borderRadius: 6,
                                                cursor: 'pointer',
                                            }}>
                                            <CustomTooltip title={(
                                                <>
                                                    <AppText
                                                        pb={1}
                                                        color="white"
                                                        fontWeight={700}>
                                                        {connection.type}
                                                    </AppText>
                                                    <AppText py={1} color="white" >
                                                        {connection.description}
                                                    </AppText>
                                                </>
                                            )}>
                                                <AppText
                                                    p={1}
                                                    fontSize={14} fontWeight={700}
                                                    color={isSelectedDataConnection ? `#FFF` : `#5D526D`}>
                                                    {connection.description}
                                                </AppText>
                                            </CustomTooltip>
                                        </Box>
                                    )
                                })}
                                <Divider />
                                <AppText
                                    py={2}
                                    fontSize={14}
                                    fontWeight={700}
                                    color="#90809D">
                                    {translatePlaceholder("DIMENSIONS")}
                                </AppText>
                                <Droppable
                                    type={MetaDataType.Dimension}
                                    droppableId={DroppableEntries.SelectedDimensions}>
                                    {(provided) => {

                                        return (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}>
                                                {selectedDimensions.map((dimension, index) => (
                                                    <Draggable
                                                        isDragDisabled={isMetadataLoading}
                                                        key={dimension.apiName}
                                                        draggableId={dimension.apiName}
                                                        index={index}>
                                                        {(provided) => (
                                                            <Box
                                                                mb={1}
                                                                key={dimension.apiName}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}>
                                                                <Box
                                                                    key={dimension.apiName}
                                                                    p={1}
                                                                    mb={1}
                                                                    style={{
                                                                        background: '#D0FFED',
                                                                        borderRadius: 6,
                                                                        cursor: 'grab'
                                                                    }} >
                                                                    <AppText
                                                                        noWrap={false}
                                                                        color="#5D526D"
                                                                        fontSize={14}
                                                                        fontWeight={700}>
                                                                        {dimension.uiName}
                                                                    </AppText>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                <Box
                                                    p={1}
                                                    style={{
                                                        transition: '250ms all',
                                                        border: `1px dashed #ADA7B7`,
                                                        borderRadius: 6,
                                                        marginBottom: (8 * 3) - 1,
                                                        marginTop: -1,
                                                        backgroundColor: (dropHighlight === DroppableEntries.Dimensions) ? '#D2D1D3' : 'inherit',
                                                    }}>
                                                    <AppText fontSize={14} fontWeight={700} color="#ADA7B7">
                                                        {translatePlaceholder("ADD_DIMENSION")}
                                                    </AppText>
                                                </Box>
                                            </div>
                                        )
                                    }}
                                </Droppable>

                                <Divider />

                                <AppText
                                    py={2}
                                    fontSize={14}
                                    fontWeight={700}
                                    color="#90809D">
                                    {translatePlaceholder("METRICS")}
                                </AppText>
                                <Droppable
                                    type={MetaDataType.Metric}
                                    droppableId={DroppableEntries.SelectedMetrics}>
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {selectedMetrics.map((metric, index) => (
                                                <Draggable
                                                    key={metric.apiName}
                                                    draggableId={metric.apiName}
                                                    index={index}>
                                                    {(provided) => (
                                                        <Box
                                                            mb={1}
                                                            key={metric.apiName}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}>
                                                            <Box
                                                                key={metric.apiName}
                                                                p={1}
                                                                mb={1}
                                                                style={{
                                                                    background: '#D7EFFF',
                                                                    borderRadius: 6,
                                                                    cursor: 'grab'
                                                                }} >
                                                                <AppText
                                                                    noWrap={false}
                                                                    color="#5D526D"
                                                                    fontSize={14}
                                                                    fontWeight={700}>
                                                                    {metric.uiName}
                                                                </AppText>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </Draggable>
                                            ))}
                                            <Box
                                                p={1}
                                                style={{
                                                    transition: '250ms all',
                                                    border: '1px dashed #ADA7B7',
                                                    borderRadius: 6,
                                                    marginTop: -1,
                                                    marginBottom: (8 * 3) - 1,
                                                    backgroundColor: (dropHighlight === DroppableEntries.Metrics) ? '#D2D1D3' : 'inherit',
                                                }}>
                                                <AppText
                                                    fontSize={14}
                                                    fontWeight={700}
                                                    color="#ADA7B7">
                                                    {translatePlaceholder("ADD_METRIC")}
                                                </AppText>
                                            </Box>
                                        </div>
                                    )}
                                </Droppable>
                                <Divider />
                                <AppText
                                    py={2}
                                    fontSize={14}
                                    fontWeight={700}
                                    color="#90809D">
                                    {translatePlaceholder("SORT")}
                                </AppText>
                                <Box px={1}>
                                    <RadioGroup
                                        value={sortDirection}
                                        onChange={(_, value: SortDirection) => setSortDirection(value)}
                                    >
                                        <FormControlLabel
                                            value={SortDirection.Ascending}
                                            control={(
                                                <Radio
                                                    style={{
                                                        color: '#8E809E'
                                                    }}
                                                />
                                            )}
                                            label={(
                                                <AppText
                                                    py={1}
                                                    color="90809D"
                                                    fontSize={14}
                                                    fontWeight={700}>
                                                    {translatePlaceholder("ASCENDING")}
                                                </AppText>
                                            )}
                                        />
                                        <FormControlLabel
                                            value={SortDirection.Descending}
                                            control={(
                                                <Radio
                                                    style={{
                                                        color: '#8E809E'
                                                    }}
                                                />
                                            )}
                                            label={(
                                                <AppText
                                                    py={1}
                                                    color="90809D"
                                                    fontSize={14}
                                                    fontWeight={700}>
                                                    {translatePlaceholder("DESCENDING")}
                                                </AppText>
                                            )}
                                        />
                                    </RadioGroup>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            p={2}
                            width={32 * 8}
                            style={{
                                background: '#E9E8EA',
                            }}>
                            <div>
                                <AppText
                                    py={1}
                                    fontWeight={700}
                                    color="primary"
                                    fontSize={16}>
                                    {translatePlaceholder("SEARCH")}
                                </AppText>
                            </div>
                            <AppInput
                                style={{
                                    borderRadius: 25
                                }}
                                endAdornment={<Search color="#B0B9C8" />}
                                placeholder={translatePlaceholder("SEARCH")}
                                value={placeholderSearchQuery}
                                onChange={e => setPlaceholderSearchQuery(e.target.value)}
                            />
                            <Box py={2}>
                                <Divider />
                            </Box>
                            <Box
                                flexGrow={1}
                                style={{
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                    height: '100%'
                                }}>
                                {isMetadataLoading && (
                                    <Box display="flex" justifyContent="center">
                                        <PageLoader />
                                    </Box>
                                )}
                                {!selectedDataConnection && !isMetadataLoading && (
                                    <AppText
                                        noWrap={false}
                                        p={2}
                                        fontSize={14}
                                        fontWeight={700}
                                        textAlign="center">
                                        {translatePlaceholder("SELECT_DATA_SOURCE")}
                                    </AppText>
                                )}
                                <Droppable
                                    type={MetaDataType.Dimension}
                                    droppableId={DroppableEntries.Dimensions}>
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {filteredDimensions.map((dimension, index) => (
                                                <Fragment key={dimension.apiName}>
                                                    <MetaDataItem
                                                        index={index}
                                                        loading={isMetadataLoading}
                                                        metaData={dimension}
                                                        color="#D0FFED"
                                                    />
                                                </Fragment>
                                            ))}
                                        </div>
                                    )}
                                </Droppable>
                                <Droppable
                                    type={MetaDataType.Metric}
                                    droppableId={DroppableEntries.Metrics}>
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {filteredMetrics.map((metric, index) => (
                                                <Fragment key={metric.apiName}>
                                                    <MetaDataItem
                                                        index={index}
                                                        loading={isMetadataLoading}
                                                        metaData={metric}
                                                        color="#D7EFFF"
                                                    />
                                                </Fragment>
                                            ))}
                                        </div>
                                    )}
                                </Droppable>
                            </Box>
                        </Box>
                    </Box>
                </DragDropContext>
            </Drawer>
        </>
    )
}

interface MetaDataItemProps {
    metaData: Metric | Dimension,
    index: number,
    loading: boolean;
    color: string;
}

const MetaDataItem = ({
    metaData,
    index,
    loading,
    color,
}: MetaDataItemProps) => {

    return useMemo(() => {

        return (
            <Draggable
                isDragDisabled={loading}
                key={metaData.apiName}
                draggableId={metaData.apiName}
                index={index}>
                {(provided, snapshot) => (
                    <Box
                        mb={1}
                        key={metaData.apiName}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Box
                            style={{
                                userSelect: 'none',
                                transition: '250ms all',
                                opacity: loading ? .4 : 1,
                                pointerEvents: loading ? 'none' : 'inherit',
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? `#C2C1C2` : 'inherit',
                                borderRadius: 6,
                            }}
                            py={.5}
                            px={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between">
                            <AppText
                                lineHeight={1.75}
                                noWrap={false}
                                fontSize={12}
                                fontWeight={600}
                                color="90809D">
                                {metaData.uiName}
                            </AppText>
                            <Chip
                                style={{
                                    height: 18,
                                    backgroundColor: color,
                                    cursor: 'inherit',
                                }}
                                label={(
                                    <AppText
                                        fontSize={8}
                                        fontWeight={400}
                                        color="#5D526D">
                                        {metaData.apiName}
                                    </AppText>
                                )}
                            />
                        </Box>
                    </Box>
                )}
            </Draggable>
        )

    }, [
        metaData,
        loading,
        color
    ]);
}

export default DataConnectorComponent;

