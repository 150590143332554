import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import React, { useMemo } from 'react';
import { classes } from './style.css';


const UpvoteToggle = () => {

	const {
		currentSlide,
		currentSlideKey,
	} = useSlides();

	const {
		setSlideSetting,
	} = useSlideActions();

	const toggleUpvoting = () => {

		const isEnabled = !Boolean(currentSlide.hasUpvoting);

		setSlideSetting('hasUpvoting', Number(isEnabled), currentSlideKey);
	}

	const { translatePlaceholder } = useTranslations();

	const upvotingEnabled = useMemo(() => {

		return Number(currentSlide.hasUpvoting);

	}, [currentSlideKey, currentSlide.hasUpvoting])

	return (
		<div>
			<div className={classes.upvoteToggleContainer}>
				<div>
					<Checkbox
						classes={{
							root: classes.checkBox,
						}}
						checked={Boolean(upvotingEnabled)}
						onChange={() => toggleUpvoting()}
						{...renderingGAAttr(`${DataElAction.ParticipantsCanVoteOtherResponses}_${Boolean(upvotingEnabled)}`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Checkbox)}
					/>
				</div>
				<div className={classes.upvoteToggleTextContainer}>
					<Typography variant="h4">
						{translatePlaceholder("UPVOTING_EXPLANATION")}
					</Typography>
				</div>
			</div>
		</div>
	);
}

export default UpvoteToggle;