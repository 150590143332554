import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import ImageOpacitySlider from 'Components/beta/settings/presentation_summary/options/general/opacity_slider/ImageOpacitySlider';
import React from 'react';
import BackgroundColorSetting from '../../../items/background_color/BackgroundColorSetting';
import TimerCountdownSetting from '../../../items/countdown/TimerCountdownSetting';
import PresentationLogoSetting from '../../../items/presentation_logo/PresentationLogoSetting';

const QuizCountdownSettings = () => {
    return (
        <>
            <TimerCountdownSetting />
            <LoginCodeEditor />
            <PresentationLogoSetting />
            <BackgroundColorSetting />
            <ImageOpacitySlider />
        </>
    );
};

export default QuizCountdownSettings;