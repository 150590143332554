import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import ImageOpacitySlider from 'Components/beta/settings/presentation_summary/options/general/opacity_slider/ImageOpacitySlider';
import React from 'react';
import BackgroundColorSetting from '../../../items/background_color/BackgroundColorSetting';
import PresentationLogoSetting from '../../../items/presentation_logo/PresentationLogoSetting';

const ContentSlideSettings = () => {
	return (
		<>
			<LoginCodeEditor />
			<PresentationLogoSetting />
            <BackgroundColorSetting />
			<ImageOpacitySlider />
		</>
	);
};



export default ContentSlideSettings;

