import { Typography } from "@mui/material";
import { useTranslations } from "Hooks/useTranslations";
import React from "react";
import { classes } from "./style.css";

const FourOFour = () => {
    const { translatePlaceholder } = useTranslations();

    const url = new URL(window.location.href);
    const myParam = url.searchParams.get('error');

    return (
        <>
            <Typography variant="h2" >{translatePlaceholder("ERROR_404")}</Typography>
            {myParam && <Typography variant="subtitle1" lineHeight={"normal"} mt={2}>{translatePlaceholder(myParam, {}, true)}</Typography>}
            <Typography textAlign="center" mt={3}>
                <a
                    className={classes.signinLink}
                    data-eltype="link"
                    data-elaction="sign_in"
                    data-elscreen="login"
                    href={'/'}>
                        {translatePlaceholder("CLICK_HERE_TO_LOG_IN")}
                </a>
            </Typography>
        </>
    );
};
export default FourOFour;