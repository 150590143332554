import { DerivedFeatures, Features } from "Hooks/useFeatures";
import { SendstepsIntegrations } from "./appTypes";
import { SlideTypes } from "./slideTypes";

export enum AfterSignupSteps {
    FirstStep,
    SecondStep,
	ThirdStep,
	FourthStep,
}

export enum SelectSlideTab {
	Questions,
	Slides,
	GetInspiration,
	AiContentCreator,
}

export enum StepperUserSegments {
    Business = 'Business',
    Fun = 'Fun',
    Education = 'Education',
    Other = 'Other',
    OneTimeEvent = 'One Time Event'
}

export enum StepperUserRoles {
    Events = 'Events',
    Marketing = 'Marketing',
    Sales = 'Sales',
    Consultancy = 'Consultancy',
    Trainer = 'Trainer',
    Engineering = 'Engineering',
    Executive = 'Executive',
    Other = 'Other',
}

export enum MobileEditorTabs {
    Slides = "Slides",
    Editor = "Editor",
    Options = "Slide options",
}

export enum SlideOptionTabs {
	Layout = 'Layout',
	Configuration = 'Configuration',
}

export enum SlideTypeTabs {
	Question = 'Question',
	Slide = 'Slide',
}


export interface SignupStepperData {
	segment: StepperUserSegments | string;
	role: StepperUserRoles | string;
	signupReason: string;
}
export interface LayoutState {
	selectedMobileEditorTab: MobileEditorTabs,
	leftDrawerOpen: boolean;
	rightDrawerOpen: boolean;
	slideIdToRemove: null | number;
	advancedPresentationOptionsShown: boolean;
	powerpointModalOpen: boolean;
	microsoftTeamsModalOpen: boolean;
	appSurveyModalOpen: boolean;
	appVersionBannerShowing: boolean;
    shownMissingFeature: Features | DerivedFeatures | null;
	showCreateFirstPresentation: boolean;
	guidedTourRunning: boolean;
	guidedTourStep: number | null;
	licenseRequestFailedOverlayOpen: boolean;
	licenseEligibleOverlayOpen: boolean;
	licenseMultiOverlayOpen: boolean;
	welcomeOverlayOpen: boolean;
	shownZohoFormUrl: string | null;
	supportPanelOpen: boolean;
	planDetailsLoading: boolean;
	paymentSuccessDialogOpen: boolean;
	paymentPendingDialogOpen: boolean;
	paymentFailedDialogOpen: boolean;
	newsFeedOpen: boolean;
	showCantDownloadAddinFromMobileDevice: boolean;
	showDeletePresentationWithoutSlides: boolean;
	selectedSettingsIntegration: SendstepsIntegrations;
	showCantEditInspirationTemplateDialog: boolean;
	shownMissingFeatures: Array<string> | null;
	selectSlideDialogActiveTab: SelectSlideTab;
	exampleSlideTypeShown: SlideTypes | null;
	selectedSlideOptionTab: SlideOptionTabs;
	selectedSlideTypeTab: SlideTypeTabs;
	showImageUploadDialog: boolean;
	showAIPresentationReadyDialog: boolean;
	showUploadDocumentDialog: boolean;
	isRewriting: boolean;
	showSlideLogoUploadDialog: boolean;
	speakerNotesExpanded: boolean;
}

export enum LayoutTypes {
	ImageOnBackground = "bgImg",
	ImageOnTop = "defaultTopImg",
	ImageOnBottom = "defaultBottomImg",
	ImageOnRightPadded = "sideImgReverse",
	ImageOnLeftPadded = "sideImg",
	ImageOnRight = "halfImgReverse",
	ImageOnLeft = "halfImg",
	ImageOnRightSmall = "partHalfImgReverse",
	ImageOnLeftSmall = "partHalfImg",
}