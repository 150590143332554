import { Box } from '@mui/material';
import { useDebouncedEffect } from 'Hooks/useDebouncedEffect';
import { colorToHex } from 'Scripts/colorHelper';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppColorPickerSelector from './AppColorPickerSelector';
import { classes } from './style.css';


export interface ColorPickerPosition {
	x: number;
	y: number;
}

export interface RGBValue {
	r: number;
	g: number;
	b: number;
}

export interface RGBAValue extends RGBValue {
	a: number;
}

interface AppColorPickerProps {
    value: string;
    setValue: (color: string) => void;
}

const AppColorPicker = ({
	value,
	setValue,
}: AppColorPickerProps) => {

	const colorCanvasRef = useRef<HTMLCanvasElement>(null);

	const [placeholderValue, setPlaceholderValue] = useState<string>("");

	const [mouseHeld, setMouseHeld] = useState<boolean>(false);

	const pickerSize = 16;

	/**
	 * Color values to fill the canvas horizontally
	 */
	const colorArray: Array<RGBValue> = useMemo(() => ([
		{ r: 255, g: 0, b: 0 },
		{ r: 255, g: 0, b: 255 },
		{ r: 0, g: 0, b: 255 },
		{ r: 0, g: 255, b: 255 },
		{ r: 0, g: 255, b: 0 },
		{ r: 255, g: 255, b: 0 },
		{ r: 255, g: 0, b: 0 },
	]), []);

	/**
	 * Color values to fill the canvas vertically
	 */
	const blackWhiteArray: Array<RGBAValue> = useMemo(() => ([
		{ r: 255, g: 255, b: 255, a: 1 },
        { r: 255, g: 255, b: 255, a: 0 },
        { r: 0, g: 0, b: 0, a: 1 },
	]), []);

	const [pointerPosition, setPointerPosition] = useState<ColorPickerPosition>({ x: 0, y: 0 });

	const handleMouseMove = (e: React.MouseEvent | MouseEvent) => {

		if (!mouseHeld) {

			return;
		}

        e.stopPropagation();
        e.preventDefault();

        updateCoordinatesFromMouseEvent(e);
	};

    const updateCoordinatesFromMouseEvent = (e: React.MouseEvent | MouseEvent) => {

        if(!colorCanvasRef.current) {

            return;
        }

        const maxX = colorCanvasRef.current.clientWidth;
        const maxY = colorCanvasRef.current.clientHeight;

        const mouseX = Math.max(0, Math.min(maxX, e.clientX - colorCanvasRef.current.getBoundingClientRect().left));
        const mouseY = Math.max(0, Math.min(maxY, e.clientY - colorCanvasRef.current.getBoundingClientRect().top));

        setPointerPosition({
            x: mouseX,
            y: mouseY,
        });
    }


    const getColorFromPointerPositions = (positions: ColorPickerPosition) => {

        if (!colorCanvasRef.current) {

            return "";
        }

        const context = colorCanvasRef.current.getContext("2d");

        if (!context) {

            return "";
        }

        const { clientHeight, clientWidth, height, width } = colorCanvasRef.current;

        const yScale = height / clientHeight;
        const xScale = width / clientWidth;

        const normalizedX = Math.max(0, (xScale * positions.x) - 1) >> 0;
        const normalizedY = yScale * positions.y;

        const { data } = context.getImageData(normalizedX, normalizedY, 1, 1);

        const [r, g, b] = data;

        const toHex = (c: number) => {

            const hex = c.toString(16);

            return hex.length === 1 ? "0" + hex : hex;
        };

        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }

    /**
     * Translates hex color to x/y positions on the color canvas
     * @warn this is relatively heavy, so only used initially
     */
    const hexToPointerPositions = (hexColor: string): ColorPickerPosition | null => {

        if (!hexColor || !colorCanvasRef.current) {

            return null;
        }

        if (!colorCanvasRef.current) {

            return null;
        }

        const context = colorCanvasRef.current.getContext("2d");

        if (!context) {

            return null;
        }

        try {

            const { clientHeight, clientWidth, height, width } = colorCanvasRef.current;

            const yScale = height / clientHeight;
            const xScale = width / clientWidth;

            const { data } = context.getImageData(0, 0, width, height);

            let minDistance = Infinity;

            let closestX = 0;
            let closestY = 0;

            for (let y = 0; y < colorCanvasRef.current.height; y++) {

                for (let x = 0; x < colorCanvasRef.current.width; x++) {

                    const index = (y * colorCanvasRef.current.width + x) * 4;

                    const r = data[index];
                    const g = data[index + 1];
                    const b = data[index + 2];

                    // console.log(r,g,b)

                    // Calculate Euclidean distance between the RGB components of the colors
                    const distance = Math.sqrt(
                        Math.pow(r - parseInt(hexColor.substring(1, 3), 16), 2) +
                        Math.pow(g - parseInt(hexColor.substring(3, 5), 16), 2) +
                        Math.pow(b - parseInt(hexColor.substring(5, 7), 16), 2)
                    );

                    // Update closest position if distance is smaller
                    if (distance < minDistance) {

                        minDistance = distance;

                        closestX = x;
                        closestY = y;
                    }
                }
            }

            return {
                x: (closestX / xScale),
                y: (closestY / yScale)
            };

        } catch (error) {

            console.warn(`Unable to translate "${hexColor}" to pointer positions.`);

            return null;
        }
    }

	const handleMouseUp = useCallback(() => {

        if(!mouseHeld) {

            return;
        }

		setMouseHeld(false);

		const color = getColorFromPointerPositions(pointerPosition);

		if(!color) {

			return;
		}

		setValue(color);

	}, [
		pointerPosition.x,
		pointerPosition.y,
	])

    /**
     * (Re)bind the mouse handlers once the mouseHeld/placeholderValue changes
     */
	useEffect(() => {

		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);

		return () => {

			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
		}

	}, [
        mouseHeld,
        placeholderValue,
    ]);

    /**
     * Update the placeholder value once the positions change
     */
	useEffect(() => {

		const newValue = getColorFromPointerPositions(pointerPosition);

		setPlaceholderValue(newValue);

	}, [
		pointerPosition.x,
		pointerPosition.y
	]);

    /**
     * Construct the canvas gradient
     */
    useEffect(() => {

        if (!colorCanvasRef.current) {

            return;
        }

        const context = colorCanvasRef.current.getContext("2d");

        if (!context) {

            return;
        }

        const canvasHeight = colorCanvasRef.current.height;
        const canvasWidth = colorCanvasRef.current.width;

        const gradient = context.createLinearGradient(0, 0, canvasWidth, 0);

        colorArray.forEach((rgb, index) => {

            const { r, g, b } = rgb;

            const normalizedStopValue = index / (colorArray.length - 1);

            gradient.addColorStop(normalizedStopValue, `rgb(${r}, ${g}, ${b})`);
        });

        context.fillStyle = gradient;
        context.fillRect(0, 0, canvasWidth, canvasHeight);

        const blackWhiteGradient = context.createLinearGradient(0, 0, 0, canvasHeight);

        blackWhiteArray.forEach((rgba, index) => {

            const { r, g, b, a } = rgba;

            const normalizedStopValue = index / (blackWhiteArray.length - 1);

            blackWhiteGradient.addColorStop(normalizedStopValue, `rgba(${r}, ${g}, ${b}, ${a})`);
        });

        context.fillStyle = blackWhiteGradient;
        context.fillRect(0, 0, canvasWidth, canvasHeight);

        /**
         * Add single line of black/white color to
         * bottom/top of canvas respectively so #ffffff
         * and #000000 can be selected
         */
        context.fillStyle = `#ffffff`;
        context.fillRect(0, 0, canvasWidth, 1);

        context.fillStyle = "#000000";
        context.fillRect(0, canvasHeight - 1, canvasWidth, 1);

    }, [colorCanvasRef.current]);

    /**
     * Only used for initially setting the values/coordinates
     */
	useEffect(() => {

        if (Boolean(placeholderValue)) {

            return;
        }

        const colorValue = colorToHex(value);

        setPlaceholderValue(colorValue);

         const positionValues = hexToPointerPositions(colorValue);

        if (!positionValues) {

            return;
        }

        setPointerPosition(positionValues);

	}, [ ]);

    /**
     * Debounced due to heavy hexToPointerPositions call
     */
    useDebouncedEffect(() => {

        const colorValue = colorToHex(value);

        const positionValues = hexToPointerPositions(colorValue);

        if (!positionValues) {

            return;
        }

        setPointerPosition(positionValues);

    }, [value], 250)

	return (
		<>
			<Box className={classes.canvasContainer} >
				<canvas
                    className={classes.canvas}
					onMouseDown={() => setMouseHeld(true)}
					onMouseUp={handleMouseUp}
					ref={colorCanvasRef}
					height={200}
				/>

				<AppColorPickerSelector
					position={pointerPosition}
					size={pickerSize}
					color={placeholderValue}
				/>
			</Box>
            {/* <Box py={2}>
                <Slider
                    min={0}
                    max={255}
                    style={{
                        padding: 0,
                        background: `linear-gradient(90deg, rgba(0,0,0,0) 0%, ${value} 80%)`
                    }}
                    value={colorToRgba(value).a}
                    classes={{
                        track: classes.sliderTrack,
                        root: classes.sliderRoot,
                        rail: classes.rail,
                    }}
                />
            </Box> */}
		</>

	);
}

export default AppColorPicker