import { Divider, Typography } from '@mui/material';
import SlideSettingButton from 'Components/beta/common/buttons/settings/slide/SlideSettingButton';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import React from 'react';
import { classes } from './style.css';

const SlidePointsSetting = () => {

	const {
		currentSlide,
		currentSlideKey,
	} = useSlides();

	const {
		setSlideSetting,
	} = useSlideActions();

	const { translatePlaceholder } = useTranslations();

	const setSlidePoints = (newPoints: number) => {

		setSlideSetting('points', newPoints, currentSlideKey);
	}

	return (
		<>
			<Typography variant="h4" className={classes.pointsText}>
				{translatePlaceholder("POINTS_FOR_CORRECT_ANSWER")}
			</Typography>
			<div className={classes.pointsContainer}>
				<div className={classes.pointsButtonContainer}>
					<SlideSettingButton
						data-testid={testIds.SLIDE_OPTION_POINTS_500}
						isActive={currentSlide.points === 500}
						value={500}
						onClick={() => setSlidePoints(500)}
						{...renderingGAAttr(`${DataElAction.PointsPerAnswer}_500`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Button)}
						/>
				</div>
				<div className={classes.pointsButtonContainer}>
					<SlideSettingButton
						data-testid={testIds.SLIDE_OPTION_POINTS_1000}
						isActive={currentSlide.points === 1000}
						value={1000}
						onClick={() => setSlidePoints(1000)}
						{...renderingGAAttr(`${DataElAction.PointsPerAnswer}_1000`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Button)}
					/>
				</div>
			</div>
			<Divider />
		</>
	);
};

export default SlidePointsSetting