import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box, useTheme } from '@mui/system';
import SlideSettingButton from 'Components/beta/common/buttons/settings/slide/SlideSettingButton';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { SlideTypes } from 'Types/slideTypes';
import React, { useEffect, useState } from 'react';
import { classes } from './style.css';

const onScreenMessageAmounts = [1, 2, 3, 4, 5];

const unlimitedMessagesAmount = 999;

const MessagesOnScreenSetting = () => {

	const {
		currentSlide,
		currentSlideKey,
	} = useSlides();

	const {
        setSlideSetting,
	} = useSlideActions();

	const [dummyMessagesAllowed, setDummyMessagesAllowed] = useState(0)

	const { translatePlaceholder } = useTranslations();

	useEffect(() => {

		setDummyMessagesAllowed(currentSlide.nrOfMessageShapes)

	}, [])

	const setAmountOfMessagesAllowed = (newValue: number) => {

		setSlideSetting('nrOfMessageShapes', newValue, currentSlideKey);
	}

	const setUnlimitedAmountOfMessagesAllowed = () => {

		setDummyMessagesAllowed(unlimitedMessagesAmount);

		setSlideSetting('nrOfMessageShapes', unlimitedMessagesAmount, currentSlideKey);
	}

	useEffect(() => {

		setDummyMessagesAllowed(currentSlide.nrOfMessageShapes);

	}, [currentSlide.nrOfMessageShapes]);

	const theme = useTheme();

	return (
		<div>
			<Typography variant="h4">
				{translatePlaceholder("MAXIMUM_RESPONSES_SHOWN")}
			</Typography>
			<div className={classes.buttonBox}>
				{onScreenMessageAmounts.map(responseValue => (
					<Box key={responseValue} className={classes.buttonContainer} sx={{
						minWidth: `calc(${100 / onScreenMessageAmounts.length}% - ${theme.spacing(1 / 4)})`,
					}}>
						<SlideSettingButton
							isActive={dummyMessagesAllowed === responseValue}
							onClick={() => setAmountOfMessagesAllowed(responseValue)}
							value={responseValue}
							{...renderingGAAttr(`${DataElAction.MaxResponseShown}_${responseValue}`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Button)}
						/>
					</Box>
				))}
			</div>
			{/* Only allowed for this question type */}
			{currentSlide.type === SlideTypes.OpenEnded && (
				<>
					<div>
						<SlideSettingButton
							isActive={dummyMessagesAllowed === unlimitedMessagesAmount}
							onClick={() => setUnlimitedAmountOfMessagesAllowed()}
							value={translatePlaceholder("ALL_WITH_SCROLL")}
						/>
					</div>
					<Divider className={classes.bottomDivider} />
				</>
			)}
		</div>
	);
};

export default MessagesOnScreenSetting;