import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import ImageOpacitySlider from 'Components/beta/settings/presentation_summary/options/general/opacity_slider/ImageOpacitySlider';
import React from 'react';
import TimerLimitSetting from '../../../../settings/items/time_limit/TimerLimitSetting';
import AnswerModeToggle from '../../../items/answer_mode/AnswerModeToggle';
import BackgroundColorSetting from '../../../items/background_color/BackgroundColorSetting';
import SlidePointsSetting from '../../../items/points_toggle/SlidePointsSetting';
import PresentationLogoSetting from '../../../items/presentation_logo/PresentationLogoSetting';
import SlideResultTypeToggle from '../../../items/result_type/SlideResultTypeToggle';


const QuizSettings = () => {
	return (
		<>
			<SlidePointsSetting />
			<AnswerModeToggle />
			<TimerLimitSetting />
			<SlideResultTypeToggle />
			<LoginCodeEditor />
			<PresentationLogoSetting />
            <BackgroundColorSetting />
			<ImageOpacitySlider />
		</>
	);
};


export default QuizSettings;