import { Box } from "@mui/material";
import React from "react";
import { ColorPickerPosition } from "./AppColorPicker";
import { classes } from "./style.css";


interface AppColorPickerSelectorProps {
    position: ColorPickerPosition,
    size: number,
    color: string;
}


const AppColorPickerSelector = ({ position, size, color }: AppColorPickerSelectorProps) => {
    return (
        <Box
            className={classes.picker}
            height={size}
            width={size}
            sx={{
                top: position.y - (size / 2),
                left: position.x - (size / 2),
            }}
        >
            <Box
                sx={{
                    borderRadius: 24,
                    backgroundColor: color,
                    height: size * .75,
                    width: size * .75,
                }}
            />
        </Box>
    )
}

export default AppColorPickerSelector;