import RedirectHelper from 'Components/beta/overlay/RedirectHelper';
import StartPresentationSummary from 'Components/beta/settings/presentation_summary/StartPresentationSummary';
import ScreenLoadingPlaceholder, { Loadable } from 'Components/common/ScreenLoadingPlaceholder';
import MissingFeatureDialog from 'Components/common/dialogs/feature/MissingFeatureDialog';
import MissingMultipleFeaturesDialog from 'Components/common/dialogs/features/MissingMultipleFeaturesDialog';
import { SessionRoutePrefix } from 'Hooks/useSessionRoutes';
import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

const PlayRoutes = Loadable(lazy(() => import('./play/PlayRoutes')));

const PreviewPlayRoutes = Loadable(lazy(() => import('./preview/PreviewPlayRoutes')));

const BetaRoutes = Loadable(lazy(() => import('./BetaRoutes')));

const MainRoutes = () => {

	return (
		<>
			<RedirectHelper />
			<Suspense fallback={<ScreenLoadingPlaceholder />}>
				<Switch>
					<Route path={`/${SessionRoutePrefix.Play}`} render={() => (
						<Suspense fallback={<ScreenLoadingPlaceholder />}>
							<PlayRoutes />
						</Suspense>
					)} />
					<Route path={`/${SessionRoutePrefix.Preview}`} render={() => (
						<PreviewPlayRoutes />
					)} />
					<Route path={'/editor/:presentationId/start'} component={StartPresentationSummary} />
					<Route render={() => (
						<BetaRoutes />
					)} />
				</Switch>
			</Suspense>
			<MissingFeatureDialog />
			<MissingMultipleFeaturesDialog />
		</>
	)
}

export default MainRoutes;