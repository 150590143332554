import { Divider, Typography } from '@mui/material';
import ModeratorPanelOption from 'Components/beta/editor/content/details/additional/moderator_panel/ModeratorPanelOption';
import { Features, useFeatures } from 'Hooks/useFeatures';
import useMessageFilter from 'Hooks/useMessageFilter';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import React, { useEffect } from 'react';
import { classes } from './style.css';

const ModeratorPanelToggle = () => {

	const { isFeatureAvailable } = useFeatures();

	const hasModeratorPanelFeature = isFeatureAvailable(Features.MESSAGE_FILTER);

	const { translatePlaceholder } = useTranslations();

	const {
		userSessionInfo,
		currentSessionId,
	} = useSession();

	const {
		moderatorSharingToken,
		fetchModeratorSharingToken,
	} = useMessageFilter();

	useEffect(() => {

		if (Boolean(currentSessionId) && !Boolean(moderatorSharingToken)) {

			fetchModeratorSharingToken();
		}

	}, [currentSessionId])

	return (
		<>
			<Typography variant="h2" my={3}>
				{translatePlaceholder("REVIEW_RESPONSES")}
			</Typography>
			<Divider />
			<div className={classes.moderatorPanelSettings}>
				<ModeratorPanelOption />
			</div>
			{Boolean(userSessionInfo?.autoApprove) && Boolean(hasModeratorPanelFeature) && <Divider />}
		</>
	);
};

export default ModeratorPanelToggle;