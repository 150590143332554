import * as appActions from 'Actions/appActions';
import { setUserSessionInfo } from 'Actions/appActions';
import ConnectionStatusWrapper from 'Components/common/banners/connection/ConnectionStatusWrapper';
import LoginDialog from 'Components/common/dialogs/login/LoginDialog';
import MainRoutes from 'Components/routes/MainRoutes';
import { useConnection } from 'Hooks/useConnection';
import { useManageUsers } from 'Hooks/useManageUsers';
import usePlan from 'Hooks/usePlan';
import usePlaySettings from 'Hooks/usePlaySettings';
import usePresentation from 'Hooks/usePresentation';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { usePresentationTemplates } from 'Hooks/usePresentationTemplates';
import useSlideActions from 'Hooks/useSlideActions';
import { useTranslations } from 'Hooks/useTranslations';
import useUserFlow from 'Hooks/useUserFlow';
import useWebSocket from 'Hooks/useWebSocket';
import { get } from 'Scripts/api';
import { getFromCookies } from 'Scripts/cookieHelper';
import { LocalStorageKeys, REFRESH_TOKEN_NAME, TOKEN_NAME } from 'Scripts/globals';
import { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage } from 'Scripts/localStorage';
import { getNewrelic } from 'Scripts/newrelicHelper';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { CookieKeys } from '../../scripts/globals';
import './App.css';
import "./Variables.css";

const App = () => {

	return (
		<ConnectionStatusWrapper>
			<AppRequests />
			<MainRoutes />
			<LoginDialog />
		</ConnectionStatusWrapper>
	)
}

const AppRequests = () => {

	const currentUser = useSelector((state: RootStateOrAny) => state.appReducer.currentUser)

	const {
		fetchAppTranslations,
	} = useTranslations();

	const {
		fetchUserFlowData,
		setIgnoreLicensePopups,
	} = useUserFlow();

	const {
		bindConnectionListener,
		unBindConnectionListener,
	} = useConnection();

	const {
		fetchManageUsersSettings,
	} = useManageUsers()

	const {
		fetchPresentationStyles,
	} = usePresentationStyles();

	const {
		attemptClaimPresentation,
		setTemplateToPreview
	} = usePresentationTemplates();

	const {
		fetchPlanDetails,
		fetchUserSubscriptionData
	} = usePlan();

	const {
		fetchPresentationPageData
	} = usePresentation();

	const {
		fetchSlideShapePresets
	} = useSlideActions();

	const {
		changeLanguage,
		fetchTranslations,
	} = usePlaySettings();

	const authToken = getFromCookies(CookieKeys.AuthToken) || getFromLocalStorage(TOKEN_NAME);

	useWebSocket({
		PLAN_UPGRADE: event => onPlanUpgrade(event),
	});

	const onPlanUpgrade = async () => {
		/** Refetch plan details (includes features and such) */
		fetchPlanDetails();

		fetchUserSubscriptionData();
	}

	const dispatch = useDispatch();

	const shareKey = getFromLocalStorage(LocalStorageKeys.ClaimPresentationKey);

	const checkIfTokenLogin = () => {

		const { search } = window.location;

		if (search) {

			const searchParams = new URLSearchParams(search);

			const urlLoginToken = searchParams.get('loginToken');

			const urlRefreshToken = searchParams.get('refreshToken');

			if (urlLoginToken || urlRefreshToken) {

				if (urlLoginToken) {

					addToLocalStorage(TOKEN_NAME, urlLoginToken);

					fetchAllData();
				}

				if (urlRefreshToken) {

					addToLocalStorage(REFRESH_TOKEN_NAME, urlRefreshToken);
				}

				const url = new URL(window.location);

				url.searchParams.delete('loginToken');
				url.searchParams.delete('refreshToken');

				window?.history.replaceState(null, null, url);
			}
		}
	}

	const getUserBrandingData = async () => {

		try {

			dispatch(appActions.setUserBrandingLoading(true));

			const { branding } = await get('users/branding');

			dispatch(appActions.setUserBrandingData(branding));

		} catch (error) {

			console.warn(error)

		} finally {

			dispatch(appActions.setUserBrandingLoading(false));
		}
	}

	const getUserSessionInfo = async () => {

		try {

			const userSessionInfo = await get('users/session-info')

			dispatch(setUserSessionInfo(userSessionInfo))

			const { language } = userSessionInfo;

			changeLanguage(language);

		} catch (error) {

			console.warn(error)
		}
	}

	useEffect(() => {

		checkIfTokenLogin();

		checkError();

	}, [])

	const attemptFetchSharedPresentation = async () => {

		if (Boolean(authToken) && Boolean(shareKey)) {

			setIgnoreLicensePopups(true);

			await attemptClaimPresentation(shareKey as string);

			await fetchPresentationPageData({});

			setTemplateToPreview(null);

			setIgnoreLicensePopups(false);

			removeFromLocalStorage(LocalStorageKeys.ClaimPresentationKey);
		}
	}

	useEffect(() => {

		attemptFetchSharedPresentation();

	}, [getFromLocalStorage(TOKEN_NAME), shareKey])

	const fetchAllData = () => {

		fetchSlideShapePresets();

		fetchPresentationStyles();

		fetchUserFlowData();

		fetchManageUsersSettings();

		getUserBrandingData();

		getUserSessionInfo();

		fetchTranslations();

		fetchPlanDetails();
	}

	useEffect(() => {

		const authToken = getFromCookies(CookieKeys.AuthToken) || getFromLocalStorage(TOKEN_NAME);

		if (authToken && !isEmpty(currentUser)) {

			fetchAllData();
		}

	}, [getFromLocalStorage(TOKEN_NAME), getFromCookies(CookieKeys.AuthToken), JSON.stringify(authToken), JSON.stringify(currentUser)])

	const checkError = () => {

		window.onerror = (message, source, lineno, colno, error) => {

			getNewrelic(nr => nr.noticeError(error || `${message}`));

			console.log('error', message, source, lineno, colno, error);

			// window.location.href = window.location.origin + '?error';
		}
	}

	useEffect(() => {

		bindConnectionListener();

		fetchAppTranslations();

		return () => unBindConnectionListener();

	}, [])

	return (
		<></>
	)
}

export default App;