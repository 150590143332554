import { CardMedia } from '@mui/material';
import React from 'react';
import { testIds } from 'Scripts/cypressTestIds';
import { getFormattedEmbedUrl } from 'Scripts/stringFormatter';

interface MediaComponentProps {
    videoURL: string;
    interactive?: boolean;
}

const MediaComponent = ({
    videoURL,
    interactive = true,
}: MediaComponentProps) => {

    return (
        <CardMedia
            height="100%"
            width="100%"
            style={{
                ...(!interactive ? { pointerEvents: 'none' } : {}),
                position: 'absolute',
                top: 0,
                bottom: 0,
                left:0,
                right: 0,
            }}
            data-testid={testIds.MEDIA_COMPONENT_FRAME}
            component="iframe"
            image={getFormattedEmbedUrl(videoURL)}
        />
    );
};

export default MediaComponent;