import { Box, Paper } from '@mui/material';
import { setHidePresentationLogo } from 'Actions/quizActions';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import AppText from 'Atomic/atoms/Text/AppText';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { useAppRouting } from 'Hooks/useAppRouting';
import { Features, useFeatures } from 'Hooks/useFeatures';
import { useLayout } from 'Hooks/useLayout';
import usePresentation from 'Hooks/usePresentation';
import { useSlideLogo } from 'Hooks/useSlideLogo';
import { useTranslations } from 'Hooks/useTranslations';
import { post } from 'Scripts/api';
import { PresentationState } from 'Types/presentationTypes';
import { EyeAlt, EyeClose, MediaImage, Trash } from 'iconoir-react';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { classes } from './style.css';


const PresentationLogoSetting = () => {

    const { isFeatureAvailable } = useFeatures();

    const { translatePlaceholder } = useTranslations();

    const { setShowSlideLogoUploadDialog, setShowMissingFeature } = useLayout();

    const { setPresentationLogo, setPresentationUserLogo } = usePresentation();

    const [visibilityLoading, setVisibilityLoading] = useState<boolean>(false);

    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

    const { presentationId } = useAppRouting();

    const hasCustomLogoFeature = isFeatureAvailable(Features.CUSTOM_PRESENTATION_LOGO);

    const presentationUserLogo = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).presentationUserLogo);

    const presentationLogo = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).presentationLogo);

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const { slideLogo, showLogo } = useSlideLogo();

    const presentationHasCustomLogo = Boolean(presentationLogo);

    const userHasCustomLogo = presentationHasCustomLogo || Boolean(presentationUserLogo);

    const clearLogo = async () => {

        if (!hasCustomLogoFeature) {

            setShowMissingFeature(Features.CUSTOM_PRESENTATION_LOGO);

            return;
        }

        try {

            let params: Record<string, any> = {}

            if (presentationHasCustomLogo) {

                params = { presentationId }
            }

            setDeleteLoading(true);

            const response = await post('presentations/remove-logo', params)


            if (response.error) {

                enqueueSnackbar(translatePlaceholder("UNABLE_TO_CLEAR_LOGO"), {
                    variant: 'error'
                });

                return;
            }

            if (presentationHasCustomLogo) {

                setPresentationLogo(null);

                return;
            }

            if (userHasCustomLogo) {

                setPresentationUserLogo(null);
            }

        } catch (error) {

            console.warn(error);

        } finally {

            setDeleteLoading(false);
        }
    }

    const replaceLogo = () => {

        if (!hasCustomLogoFeature) {

            setShowMissingFeature(Features.CUSTOM_PRESENTATION_LOGO);

            return;
        }

        setShowSlideLogoUploadDialog(true);
    }

    const toggleLogoVisibility = async () => {

        if (!hasCustomLogoFeature) {

            setShowMissingFeature(Features.CUSTOM_PRESENTATION_LOGO);

            return;
        }

        try {

            setVisibilityLoading(true);

            const hideLogo = await post(`presentations/${presentationId}/logo-visibility`);

            if (hideLogo?.error) {

                enqueueSnackbar(translatePlaceholder("SOMETHING_WENT_WRONG"), {
                    variant: 'error'
                });

                return;
            }

            dispatch(setHidePresentationLogo(hideLogo));

        } catch (error) {

            console.warn(error);

        } finally {

            setVisibilityLoading(false);
        }
    }

    const isLoading = (
        visibilityLoading ||
        deleteLoading
    );

    const deleteDisabled = (!userHasCustomLogo || isLoading)

    return (
        <div>
            <Box display="flex" justifyContent="space-between">
                <AppText py={1.5} fontSize={14} color="#000" fontWeight={600}>
                    {!hasCustomLogoFeature && "💎"} {translatePlaceholder("LOGO_IMAGE")}
                </AppText>
            </Box>
            <div>
                <Paper>
                    <Box display="flex" p={2}>
                        <Box px={2} style={{
                            borderRadius: 8,
                            border: '1px dashed #ADA7B7',
                            background: 'rgb(0 0 0 / 10%)',
                            objectFit: 'contain'
                        }} flexGrow={1} display="flex" alignItems="center" justifyContent="center">
                            <img src={slideLogo} width="100%" />
                        </Box>
                        <Box pl={1} display="flex" flexDirection="column" justifyContent="space-evenly">
                            <CustomTooltip title={translatePlaceholder("TOGGLE_LOGO_VISIBILITY_TOOLTIP")}>
                                <AppButton
                                    disabled={isLoading}
                                    loading={visibilityLoading}
                                    startIcon={(
                                        <>
                                            {showLogo && <EyeClose fontSize={12} />}
                                            {!showLogo && <EyeAlt fontSize={12} />}
                                        </>
                                    )}
                                    className={classes.logoButton}
                                    onClick={toggleLogoVisibility}
                                    as={AppButtonVariant.Plain}>
                                    <AppText fontSize={12} textAlign="left">
                                        {showLogo && translatePlaceholder("HIDE")}
                                        {!showLogo && translatePlaceholder("SHOW")}
                                    </AppText>
                                </AppButton>
                            </CustomTooltip>

                            <Box pb={1} />
                            <CustomTooltip title={translatePlaceholder("REPLACE_LOGO_TOOLTIP")}>
                                <AppButton
                                    disabled={isLoading}
                                    startIcon={(
                                        <MediaImage fontSize={12} />
                                    )}
                                    className={classes.logoButton}
                                    onClick={replaceLogo}
                                    as={AppButtonVariant.Plain}>
                                    <AppText fontSize={12} textAlign="left">
                                        {translatePlaceholder("REPLACE")}
                                    </AppText>
                                </AppButton>
                            </CustomTooltip>
                            <Box pb={1} />
                            <CustomTooltip title={translatePlaceholder("DELETE_LOGO_TOOLTIP")}>
                                <AppButton
                                    onClick={clearLogo}
                                    loading={deleteLoading}
                                    disabled={deleteDisabled}
                                    startIcon={(
                                        <Trash fontSize={12} />
                                    )}
                                    className={classes.logoButton}
                                    as={AppButtonVariant.Plain}>
                                    <AppText fontSize={12} textAlign="left">
                                        {translatePlaceholder("DELETE")}
                                    </AppText>
                                </AppButton>
                            </CustomTooltip>
                        </Box>
                    </Box>
                </Paper>
            </div>
        </div>
    );
};

export default PresentationLogoSetting;