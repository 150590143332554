import { classes } from './style.css';

import { Box } from '@mui/system';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { PlaySlideType } from 'Types/playTypes';
import { SlideType } from 'Types/presentationTypes';
import React, { ReactNode } from 'react';

export interface SeasonalWrapperProps {
    isFirstSlide?: boolean;
    slide?: SlideType | PlaySlideType;
    children: ReactNode | Array<ReactNode>;
}

const SlideStyleWrapper = ({ isFirstSlide = false, children, slide }: SeasonalWrapperProps) => {

    const { presentationStyle } = usePresentationStyles();

    const backgroundImageToUse = isFirstSlide && presentationStyle?.indexSlideBackgroundImage
        ? presentationStyle?.indexSlideBackgroundImage
        : presentationStyle?.backgroundImage
        ;

    return (
        <Box className={classes.backgroundWrapper} sx={{
            backgroundColor: presentationStyle?.lightColor,
        }} >
            <Box
                className={classes.backgroundWrapper}
                sx={{
                    backgroundColor: slide?.backgroundColor,
                }}>
                <Box className={classes.backgroundImageWrapper} sx={{
                    backgroundImage: `url("${backgroundImageToUse}")`,
                }}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default SlideStyleWrapper;