import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import React from 'react';
import PresentationLogoSetting from '../../../items/presentation_logo/PresentationLogoSetting';

const VideoSlideSettings = () => {
    return (
        <>
            <LoginCodeEditor />
            <PresentationLogoSetting />
        </>
    );
};

export default VideoSlideSettings;