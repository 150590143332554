import usePresentationStyles from 'Hooks/usePresentationStyles';
import React from 'react';

const PreviewQuoteSymbolSlideShape = () => {

    const { presentationStyle } = usePresentationStyles();

    return (
        <div>
            <svg style={{ fill: presentationStyle?.textColor }} width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="M101.333 37.8332L85.3327 37.8332L74.666 59.1665L74.666 91.1665L106.666 91.1665L106.666 59.1665L90.666 59.1665L101.333 37.8332ZM47.9994 37.8332L31.9994 37.8332L21.3327 59.1665L21.3327 91.1665L53.3327 91.1665L53.3327 59.1665L37.3327 59.1665L47.9994 37.8332Z" />
                <mask id="mask0_11873_265086" style={{ maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="21" y="37" width="86" height="55">
                    <path fillRule="evenodd" clipRule="evenodd" d="M101.333 37.8332L85.3327 37.8332L74.666 59.1665L74.666 91.1665L106.666 91.1665L106.666 59.1665L90.666 59.1665L101.333 37.8332ZM47.9994 37.8332L31.9994 37.8332L21.3327 59.1665L21.3327 91.1665L53.3327 91.1665L53.3327 59.1665L37.3327 59.1665L47.9994 37.8332Z" />
                </mask>
            </svg>
        </div>
    );
};

export default PreviewQuoteSymbolSlideShape;