import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import ImageOpacitySlider from 'Components/beta/settings/presentation_summary/options/general/opacity_slider/ImageOpacitySlider';
import React from 'react';
import BackgroundColorSetting from '../../../items/background_color/BackgroundColorSetting';
import MultipleAnswerSetting from '../../../items/multiple_answers/MultipleAnswerSetting';
import PresentationLogoSetting from '../../../items/presentation_logo/PresentationLogoSetting';
import SlideResultTypeToggle from '../../../items/result_type/SlideResultTypeToggle';
import TimerLimitSetting from '../../../items/time_limit/TimerLimitSetting';

const MultiplechoiceSettings = () => {

	return (
		<>
			<SlideResultTypeToggle />
			<MultipleAnswerSetting />
			<TimerLimitSetting />
			<LoginCodeEditor />
			<PresentationLogoSetting />
            <BackgroundColorSetting />
			<ImageOpacitySlider />
		</>
	);
};

export default MultiplechoiceSettings;