import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import ImageOpacitySlider from 'Components/beta/settings/presentation_summary/options/general/opacity_slider/ImageOpacitySlider';
import React from 'react';
import BackgroundColorSetting from '../../../items/background_color/BackgroundColorSetting';
import ParticipantResponsesSetting from '../../../items/participant_responses/ParticipantResponsesSetting';
import PresentationLogoSetting from '../../../items/presentation_logo/PresentationLogoSetting';
import UpvoteToggle from '../../../items/upvoting_toggle/UpvoteToggle';

const WordcloudSettings = () => {
	return (
		<>
			<ParticipantResponsesSetting />
			<UpvoteToggle />
			<LoginCodeEditor />
			<PresentationLogoSetting />
			<BackgroundColorSetting />
			<ImageOpacitySlider />
		</>
	);
};

export default WordcloudSettings;