import { Collapse, Divider, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Box, useTheme } from '@mui/system';
import FeatureWrapper from 'Atomic/atoms/FeatureWrapper/FeatureWrapper';
import SlideSettingButton from 'Components/beta/common/buttons/settings/slide/SlideSettingButton';
import { Features, useFeatures } from 'Hooks/useFeatures';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import React, { useEffect, useMemo, useState } from 'react';
import { classes } from './style.css';

const maximumVoteAmountOptions = [1, 2, 3, 5, 10];

const MultipleAnswerSetting = () => {

	const {
		currentSlide,
		currentSlideKey,
	} = useSlides();

	const {
        setSlideSetting,
	} = useSlideActions();

	const {
		isFeatureAvailable
	} = useFeatures();

	const { translatePlaceholder } = useTranslations();

	const [placeholderVoteAmount, setPlaceholderVoteAmount] = useState<number>(0);

	useEffect(() => {

		setPlaceholderVoteAmount(currentSlide.nrOfVotes)

	}, [])

	const hasMultipleVoteFeature = isFeatureAvailable(Features.MULTIPLE_VOTE);

	const setAmountOfVotesAllowed = (amount: number) => {

		if (amount === 1) {

			setSlideSetting('allowMultipleVotesPerAnswer', Number(false), currentSlideKey);
		}

		setSlideSetting('nrOfVotes', amount, currentSlideKey);
	}

	useEffect(() => {

		setPlaceholderVoteAmount(currentSlide.nrOfVotes);

	}, [currentSlide.nrOfVotes])


	const allowVotesPerAnswer = useMemo(() => {

		return Number(currentSlide?.allowMultipleVotesPerAnswer);

	}, [currentSlideKey, currentSlide?.allowMultipleVotesPerAnswer])

	const toggleMultipleAnswers = () => {

		setSlideSetting('allowMultipleVotesPerAnswer', Number(!allowVotesPerAnswer), currentSlideKey);
	}

	const theme = useTheme();

	return (
		<div>

			<div className={classes.voteAmountContainer}>
				<Typography variant="h4" className={classes.voteAmountText}>
					{!hasMultipleVoteFeature && "💎"} {translatePlaceholder("MAXIMUM_VOTES_PER_PARTICIPANT")}
				</Typography>
				<div className={classes.buttonBox}>
					{maximumVoteAmountOptions.map(voteAmount => (
						<Box key={voteAmount} className={classes.buttonContainer} sx={{
							minWidth: `calc(${100 / (maximumVoteAmountOptions.length)}% - ${theme.spacing(1 / 4)})`,
						}}>
							<FeatureWrapper
								data-elscreen={DataElScreen.Editor}
								feature={Features.MULTIPLE_VOTE} >
								<SlideSettingButton
									isActive={placeholderVoteAmount === voteAmount}
									onClick={() => setAmountOfVotesAllowed(voteAmount)}
									value={voteAmount}
									data-testid={`${testIds.MULTIPLE_VOTE_BUTTON}-${voteAmount}`}
									{...renderingGAAttr(`${DataElAction.SelectMaxVotes}_${voteAmount}${!hasMultipleVoteFeature ? '_limited' : ''}`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Button)}
								/>
							</FeatureWrapper>
						</Box>
					))}
				</div>
				{/* <div>
					<SlideSettingButton
						isActive={placeholderVoteAmount === 0}
						onClick={() => setAmountOfVotesAllowed(0)}
						value={"Unlimited"}
					/>
				</div> */}
			</div>
			<Divider />
			<Collapse in={Boolean(placeholderVoteAmount !== 1)}>
				<div className={classes.multiAnswerToggleContainer}>
					<div>
						<Checkbox
							classes={{
								root: classes.checkBox,
							}}
							checked={Boolean(allowVotesPerAnswer)}
							onChange={() => toggleMultipleAnswers()}
							{...renderingGAAttr(`${DataElAction.AllowMultipleVotes}_${Boolean(allowVotesPerAnswer)}`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Checkbox)}
						/>
					</div>
					<div className={classes.answerToggleText}>
						<Typography variant="h4">
							{translatePlaceholder("ALLOW_MULTIPLE_VOTES_PER_ANSWER")}
						</Typography>
					</div>
				</div>
			</Collapse>
			<Divider />

		</div>
	);
}

export default MultipleAnswerSetting;