import { Collapse, Divider, InputBase, MenuItem, Select, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import React, { useMemo } from 'react';
import { classes } from './style.css';


const durationOptionsInSeconds = [ 10, 15, 20, 30, 60 ];

const [ defaultTiming ] = durationOptionsInSeconds

const TimerLimitSetting = () => {

	const {
		currentSlide,
		currentSlideProperties,
		currentSlideKey,
	} = useSlides();

	const {
		setSlideSetting,
	} = useSlideActions();

	const toggleTimer = () => {

		const newTiming = Boolean(currentSlide.dynamicTimerLimit) ? 0 : defaultTiming

		updateTiming(newTiming);
	}

	const updateTiming = (newValue: number) => {

		setSlideSetting('dynamicTimerLimit', newValue, currentSlideKey)
	}

	const { translatePlaceholder } = useTranslations();

	const timing = useMemo(() => {

		return Number(currentSlide.dynamicTimerLimit);

	}, [currentSlideKey, currentSlide.dynamicTimerLimit, currentSlide.type])

	return (
		<div>
			<div className={classes.timerContainer}>
				<div>
					<Checkbox
						data-testid={testIds.SLIDE_OPTION_TIME_LIMIT_TOGGLE}
						classes={{
							root: classes.checkBox,
						}}
						checked={Boolean(timing)}
						onChange={() => toggleTimer()}
						{...renderingGAAttr(`${DataElAction.SetTimeLimit}_${Boolean(timing)}`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Checkbox)}
					/>
				</div>
				<div className={classes.timeLimitTextContainer}>
					<Typography variant="h4">
						{translatePlaceholder("TIME_LIMIT")}
					</Typography>
				</div>
			</div>
			<Collapse in={Boolean(timing)}>
				<div>
					<Select
						value={`${timing || defaultTiming} Seconds`}
						input={<InputBase classes={{ input: classes.selectInput }} />}
						className={classes.selectList}
						{...renderingGAAttr(`${DataElAction.SetTimeLimit}_${timing || defaultTiming}`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Dropdown)}
					>
						{durationOptionsInSeconds.map(duration => (
							<MenuItem
								value={`${duration} Seconds`}
								disabled={duration === timing}
								key={duration}
								onClick={() => updateTiming(duration)}
								className={classes.slideTypeMenuItem}>
								<Typography>
									{duration} {translatePlaceholder("SECONDS")}
								</Typography>
							</MenuItem>
						))}
					</Select>
					<Divider />
				</div>
			</Collapse>
		</div>
	);
}

export default TimerLimitSetting;