import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Divider, Grid, InputBase, Slider, Typography } from '@mui/material';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElPosition, DataElScreen, DataElType } from 'Scripts/measurementsGAHelper';
import React, { useState } from 'react';
import { classes } from './style.css';


const minTimerValue = 3;

const maxTimerValue = 30;

const TimerCountdownSetting = () => {

    const {
        currentSlide,
        currentSlideKey,
    } = useSlides();

    const {
        setSlideSetting,
	} = useSlideActions();

    const [placeholderValue, setPlaceholderValue] = useState<number>(currentSlide.dynamicTimerLimit);

    const updateTiming = (newValue: number) => {

        const formattedValue = Math.min(maxTimerValue, Math.max(minTimerValue, newValue));

        setSlideSetting('dynamicTimerLimit', formattedValue, currentSlideKey);

        setPlaceholderValue(formattedValue);
    }

    const { translatePlaceholder } = useTranslations();

    return (
        <div>
            <div className={classes.timerContainer}>
                <div className={classes.timeLimitTextContainer}>
                    <Typography variant="h4">
                        {translatePlaceholder("COUNTDOWN_SECONDS")}
					</Typography>
                </div>
            </div>
            <Divider />
            <Grid container py={2} spacing={2} display="flex" alignItems="center">
                <Grid item >
                    <TimerOutlinedIcon />
                </Grid>
                <Grid item xs>
                    <Slider
                        min={3}
                        max={30}
                        onChange={(_, value) => setPlaceholderValue(value)}
                        onChangeCommitted={() => updateTiming(placeholderValue)}
                        value={placeholderValue}
                    />
                </Grid>
                <Grid item>
                    <div>
                        <InputBase
                            value={placeholderValue}
                            size="small"
                            onChange={(e) => setPlaceholderValue(e.target.value)}
                            onBlur={() => updateTiming(placeholderValue)}
                            inputProps={{
                                min: 3,
                                max: 30,
                                type: 'number',
                                style: {
                                    fontWeight: 600,
                                }
                            }}
                            data-elaction={DataElAction.SetQuizCountdown}
                            data-eltype={DataElType.Slider}
                            data-elscreen={DataElScreen.Editor}
                            data-elposition={DataElPosition.SettingsContainer}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default TimerCountdownSetting;