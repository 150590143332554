import MPCQuestionSvgIcon from 'Assets/images/icons/question_types/mpc_question_icon.svg';
import OpenEndedQuestionSvgIcon from 'Assets/images/icons/question_types/open_ended_question_icon.svg';
import PointsQuestionSvgIcon from 'Assets/images/icons/question_types/points_question_icon.svg';
import QAQuestionSvgIcon from 'Assets/images/icons/question_types/q&a_question_icon.svg';
import QuizQuestionSvgIcon from 'Assets/images/icons/question_types/quiz_question_icon.svg';
import WordcloudQuestionSvgIcon from 'Assets/images/icons/question_types/wordcloud_question_icon.svg';
import BarGraphSlideSvgIcon from 'Assets/images/icons/slide_types/bar_graph_slide_icon.svg';
import ContentSlideSvgIcon from 'Assets/images/icons/slide_types/content_slide_icon.svg';
import CountdownSlideSvgIcon from 'Assets/images/icons/slide_types/countdown_slide_icon.svg';
import ImageSlideSvgIcon from 'Assets/images/icons/slide_types/image_slide_icon.svg';
import LeaderboardSlideSvgIcon from 'Assets/images/icons/slide_types/leaderboard_slide_icon.svg';
import LineGraphSlideSvgIcon from 'Assets/images/icons/slide_types/line_graph_slide_icon.svg';
import PodiumSlideIconSvg from 'Assets/images/icons/slide_types/podium_slide_icon.svg';
import QuoteSlideSvgIcon from 'Assets/images/icons/slide_types/quote_slide_icon.svg';
import TableSlideSvgIcon from 'Assets/images/icons/slide_types/table_slide_icon.svg';
import TitleAndSubtitleSlideSvgIcon from 'Assets/images/icons/slide_types/title_and_subtitle_slide_icon.svg';
import TitleSlideSvgIcon from 'Assets/images/icons/slide_types/title_slide_icon.svg';
import VideoSlideSvgIcon from 'Assets/images/icons/slide_types/video_slide.svg';
import BarGraphSlideExampleImage from 'Assets/images/slide_examples/bar_chart_example.png';
import CountdownSlideExampleImage from 'Assets/images/slide_examples/countdown_example.png';
import ImageSlideExampleImage from 'Assets/images/slide_examples/image_example.png';
import LineGraphSlideExampleImage from 'Assets/images/slide_examples/line_chart_example.png';
import MPCSlideExampleImage from 'Assets/images/slide_examples/mpc_example.png';
import OpenEndedSlideExampleImage from 'Assets/images/slide_examples/open_ended_example.png';
import PointsSlideExampleImage from 'Assets/images/slide_examples/points_example.png';
import QASlideExampleImage from 'Assets/images/slide_examples/qa_example.png';
import QuizSlideExampleImage from 'Assets/images/slide_examples/quiz_example.png';
import QuoteSlideExampleImage from 'Assets/images/slide_examples/quote_example.png';
import TableSlideExampleImage from 'Assets/images/slide_examples/table_example.png';
import TitleAndSubtitleSlideExampleImage from 'Assets/images/slide_examples/title_and_subtitle_example.png';
import TitleAndTextSlideExampleImage from 'Assets/images/slide_examples/title_and_text_example.png';
import TitleSlideExampleImage from 'Assets/images/slide_examples/title_example.png';
import VideoSlideExampleImage from 'Assets/images/slide_examples/video_example.png';
import WordcloudSlideExampleImage from 'Assets/images/slide_examples/wordcloud_example.png';
import { getPlaceholderTableData, getRandomPlaceholderChartData } from 'Atomic/organisms/AppDynamicTable/AppDynamicTable';
import { AppChartType } from 'Atomic/organisms/Chart/AppChart';
import AdditionalMultiplechoiceSettings from 'Components/beta/editor/content/details/additional/question_types/multiple_choice/AdditionalMultiplechoiceSettings';
import AdditionalOpenEndedSettings from 'Components/beta/editor/content/details/additional/question_types/open_ended/AdditionalOpenEndedSettings';
import AdditionalQuizSettings from 'Components/beta/editor/content/details/additional/question_types/quiz/AdditionalQuizSettings';
import AdditionalUpvotingSettings from 'Components/beta/editor/content/details/additional/question_types/upvoting/AdditionalUpvotingSettings';
import AdditionalWordcloudSettings from 'Components/beta/editor/content/details/additional/question_types/wordcloud/AdditionalWordcloudSettings';
import ContentSlideSettings from 'Components/beta/editor/settings/slide/question_types/content_slide/ContentSlideSettings';
import QuizCountdownSettings from 'Components/beta/editor/settings/slide/question_types/countdown_slide/QuizCountdownSettings';
import ImageSlideSettings from 'Components/beta/editor/settings/slide/question_types/image_slide/ImageSlideSettings';
import MultiplechoiceSettings from 'Components/beta/editor/settings/slide/question_types/multiplechoice/MultiplechoiceSettings';
import OpenEndedSettings from 'Components/beta/editor/settings/slide/question_types/open_ended/OpenEndedSettings';
import PointsSettings from 'Components/beta/editor/settings/slide/question_types/points/PointsSettings';
import QuizSettings from 'Components/beta/editor/settings/slide/question_types/quiz/QuizSettings';
import TableSlideSettings from 'Components/beta/editor/settings/slide/question_types/table/TableSlideSettings';
import UpvotingSettings from 'Components/beta/editor/settings/slide/question_types/upvoting/UpvotingSettings';
import VideoSlideSettings from 'Components/beta/editor/settings/slide/question_types/video_slide/VideoSlideSettings';
import WordcloudSettings from 'Components/beta/editor/settings/slide/question_types/wordcloud/WordcloudSettings';
import ContentSlideEditor from 'Components/slides/types/content/editor/form/ContentSlideEditor';
import ContentSlideEditorPreview from 'Components/slides/types/content/editor/preview/ContentSlideEditorPreview';
import ContentSlideSessionPreview from 'Components/slides/types/content/session/preview/ContentSlideSessionPreview';
import ContentSlideSession from 'Components/slides/types/content/session/view/ContentSlideSession';
import QuizCountdownSlideEditor from 'Components/slides/types/countdown_slide/editor/form/QuizCountdownSlideEditor';
import QuizCountdownSlideEditorPreview from 'Components/slides/types/countdown_slide/editor/preview/QuizCountdownSlideEditorPreview';
import QuizCountdownSlideSessionPreview from 'Components/slides/types/countdown_slide/session/preview/QuizCountdownSlideSessionPreview';
import QuizCountdownSlideSession from 'Components/slides/types/countdown_slide/session/view/QuizCountdownSlideSession';
import GraphSlideEditor from 'Components/slides/types/graph/editor/form/GraphSlideEditor';
import GraphSlideSession from 'Components/slides/types/graph/session/view/GraphSlideSession';
import ImageSlideEditor from 'Components/slides/types/image_slide/editor/form/ImageSlideEditor';
import ImageSlideEditorPreview from 'Components/slides/types/image_slide/editor/preview/ImageSlideEditorPreview';
import ImageSlideSessionPreview from 'Components/slides/types/image_slide/session/preview/ImageSlideSessionPreview';
import ImageSlideSession from 'Components/slides/types/image_slide/session/view/ImageSlideSession';
import MultiplechoiceSlideEditor from 'Components/slides/types/multiplechoice/editor/form/MultiplechoiceSlideEditor';
import MultiplechoiceSlideEditorPreview from 'Components/slides/types/multiplechoice/editor/preview/MultiplechoiceSlideEditorPreview';
import MultiplechoiceSlideSessionPreview from 'Components/slides/types/multiplechoice/session/preview/MultipleChoiceSlideSessionPreview';
import MultiplechoiceSlideSession from 'Components/slides/types/multiplechoice/session/view/MultiplechoiceSlideSession';
import OpenEndedSlideEditor from 'Components/slides/types/open_ended/editor/form/OpenEndedSlideEditor';
import OpenEndedSlideEditorPreview from 'Components/slides/types/open_ended/editor/preview/OpenEndedSlideEditorPreview';
import OpenEndedSlideSessionPreview from 'Components/slides/types/open_ended/session/preview/OpenEndedSlideSessionPreview';
import OpenEndedSlideSession from 'Components/slides/types/open_ended/session/view/OpenEndedSlideSession';
import PointsSlideEditor from 'Components/slides/types/points/editor/form/PointsSlideEditor';
import PointsSlideEditorPreview from 'Components/slides/types/points/editor/preview/PointsSlideEditorPreview';
import QASlideEditor from 'Components/slides/types/qa/editor/form/QASlideEditor';
import QASlideEditorPreview from 'Components/slides/types/qa/editor/preview/QASlideEditorPreview';
import QASlideSessionPreview from 'Components/slides/types/qa/session/preview/QASlideSessionPreview';
import QASlideSession from 'Components/slides/types/qa/session/view/QASlideSession';
import QuizSlideEditor from 'Components/slides/types/quiz/editor/form/QuizSlideEditor';
import QuizSlideEditorPreview from 'Components/slides/types/quiz/editor/preview/QuizSlideEditorPreview';
import QuizSlideSessionPreview from 'Components/slides/types/quiz/session/preview/QuizSlideSessionPreview';
import QuizSlideSession from 'Components/slides/types/quiz/session/view/QuizSlideSession';
import QuoteSlideEditor from 'Components/slides/types/quote_slide/editor/form/QuoteSlideEditor';
import QuoteSlideEditorPreview from 'Components/slides/types/quote_slide/editor/preview/QuoteSlideEditorPreview';
import QuoteSlideSessionPreview from 'Components/slides/types/quote_slide/session/preview/QuoteSlideSessionPreview';
import QuoteSlideSession from 'Components/slides/types/quote_slide/session/view/QuoteSlideSession';
import TableSlideEditor from 'Components/slides/types/table/editor/form/TableSlideEditor';
import TableSlideSession from 'Components/slides/types/table/session/view/TableSlideSession';
import TitleAndSubtitleSlideEditor from 'Components/slides/types/title_and_subtitle_slide/editor/form/TitleAndSubtitleSlideEditor';
import TitleAndSubtitleSlideEditorPreview from 'Components/slides/types/title_and_subtitle_slide/editor/preview/TitleAndSubtitleSlideEditorPreview';
import TitleAndSubtitleSlideSessionPreview from 'Components/slides/types/title_and_subtitle_slide/session/preview/TitleAndSubtitleSlideSessionPreview';
import TitleAndSubtitleSlideSession from 'Components/slides/types/title_and_subtitle_slide/session/view/TitleAndSubtitleSlideSession';
import TitleSlideEditor from 'Components/slides/types/title_slide/editor/form/TitleSlideEditor';
import TitleSlideEditorPreview from 'Components/slides/types/title_slide/editor/preview/TitleSlideEditorPreview';
import TitleSlideSessionPreview from 'Components/slides/types/title_slide/session/preview/TitleSlideSessionPreview';
import TitleSlideSession from 'Components/slides/types/title_slide/session/view/TitleSlideSession';
import VideoSlideEditor from 'Components/slides/types/video_slide/editor/form/VideoSlideEditor';
import VideoSlideEditorPreview from 'Components/slides/types/video_slide/editor/preview/VideoSlideEditorPreview';
import VideoSlideSessionPreview from 'Components/slides/types/video_slide/session/preview/VideoSlideSessionPreview';
import VideoSlideSession from 'Components/slides/types/video_slide/session/view/VideoSlideSession';
import WordCloudSlideEditor from 'Components/slides/types/wordcloud/editor/form/WordCloudSlideEditor';
import WordCloudSlideEditorPreview from 'Components/slides/types/wordcloud/editor/preview/WordCloudSlideEditorPreview';
import WordCloudSlideSessionPreview from 'Components/slides/types/wordcloud/session/preview/WordCloudSlideSessionPreview';
import WordCloudSlideSession from 'Components/slides/types/wordcloud/session/view/WordCloudSlideSession';
import { Features } from 'Hooks/useFeatures';
import { config } from 'Scripts/config';
import { testIds } from 'Scripts/cypressTestIds';
import { navigationStatesPerSlideType } from 'Scripts/slideHelper';
import { PlaySlideType } from 'Types/playTypes';
import { ContentPlaceholderText, ContentSlideType, MessageRoundType, SlideProperties, TitlePlaceholderText, VoteType } from 'Types/presentationTypes';
import { SlideInputFontSize, SlideTypes } from 'Types/slideTypes';
import React from 'react';
import { ContentSlideModel } from './ContentSlideModel';
import { MessageRoundModel } from './MessageRoundModel';
import { BaseTypes } from './Slide';
import { VoteModel } from './VoteModel';


const QuizVote = (): SlideProperties => ({
	baseType: BaseTypes.Vote,
	// validation: (slide: VoteType) => validateQuizSlide(slide),
	emptySlide: (slideIndex: number, id: number, presentationId: number): VoteType => ({
		...VoteModel({
			id,
			presentationId,
			slideIndex
		}),
		type: SlideTypes.Quiz,
		dynamicTimerLimit: config.defaults.defaultAnswerTime,
		nrOfVotes: 1,
		weightedVoting: 0,
		isQuizVote: 1,
		points: config.defaults.defaultPointsAwarded,
		showScores: config.defaults.defaultShowScores,
		strictAnswers: 0,
	}),
	slideComponents: {
		slideEditorSettings: <QuizSettings />,
		slideEditorAdditionalOptions: <AdditionalQuizSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <QuizSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: VoteType) => <QuizSlideSessionPreview slide={{ ...slide, points: 500 }} />,
		slideEditorComponent: (slide: VoteType) => <QuizSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: VoteType) => <QuizSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <QuizQuestionSvgIcon />,
		slideExample: QuizSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
		optionFontSize: SlideInputFontSize.Medium,
	},
	text: {
		name: 'Quiz',
		nameTranslationKey: "QUIZ",
		alias: 'quiz',
		details: 'Play a quiz with your audience and show the winner.',
		detailsTranslationKey: "QUIZ_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddQuestion,
		getHtmlTitlePlaceholder: (title: string) => (
			`<p style="text-align: left; font-weight: 500;" >${title || ``}</p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_QUIZ,
		addSlide: testIds.ADD_SLIDE_QUIZ,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Quiz],
})

const MPCVote = (): SlideProperties => ({
	baseType: BaseTypes.Vote,
	// validation: (slide: VoteType) => validateMPCSlide(slide),
	emptySlide: (slideIndex: number, id: number, presentationId: number): VoteType => ({
		...VoteModel({
			id,
			presentationId,
			slideIndex
		}),
		type: SlideTypes.MultipleChoice,
		dynamicTimerLimit: 0,
		nrOfVotes: 1,
		weightedVoting: 0,
		isQuizVote: 0,
		points: 0,
		showScores: 0,
	}),
	slideComponents: {
		slideEditorSettings: <MultiplechoiceSettings />,
		slideEditorAdditionalOptions: <AdditionalMultiplechoiceSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <MultiplechoiceSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: VoteType) => <MultiplechoiceSlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: VoteType) => <MultiplechoiceSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: VoteType) => <MultiplechoiceSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <MPCQuestionSvgIcon />,
		slideExample: MPCSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
		optionFontSize: SlideInputFontSize.Medium,
	},
	text: {
		name: 'Multiple-choice',
		nameTranslationKey: "MPC",
		alias: 'mpc',
		details: 'Let your audience vote on one of the answers.',
		detailsTranslationKey: "MPC_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddQuestion,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: left; font-weight: 500;" >${title || ``}</p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_MPC,
		addSlide: testIds.ADD_SLIDE_MPC,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.MultipleChoice],
})

const WordCloudQuestion = (): SlideProperties => ({
	baseType: BaseTypes.MessageRound,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number): MessageRoundType => ({
		...MessageRoundModel({
			id,
			slideIndex,
			presentationId,
		}),
		type: SlideTypes.Wordcloud,
		isWordcloudQuestion: 1,
	}),
	slideComponents: {
		slideEditorSettings: <WordcloudSettings />,
		slideEditorAdditionalOptions: <AdditionalWordcloudSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <WordCloudSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: MessageRoundType) => <WordCloudSlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: MessageRoundType) => <WordCloudSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: MessageRoundType) => <WordCloudSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <WordcloudQuestionSvgIcon />,
		slideExample: WordcloudSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Wordcloud',
		nameTranslationKey: "WORDCLOUD",
		alias: 'wordcloud',
		details: 'Collect short responses from your audience.',
		detailsTranslationKey: "WORDCLOUD_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddQuestion,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: left; font-weight: 500;" >${title || ``}</p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_WORDCLOUD,
		addSlide: testIds.ADD_SLIDE_WORDCLOUD,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Wordcloud],
})

const OpenEndedQuestion = (): SlideProperties => ({
	baseType: BaseTypes.MessageRound,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number): MessageRoundType => ({
		...MessageRoundModel({
			id,
			slideIndex,
			presentationId,
		}),
		type: SlideTypes.OpenEnded,
		nrOfMessagesAllowed: 1,
	}),
	slideComponents: {
		slideEditorSettings: <OpenEndedSettings />,
		slideEditorAdditionalOptions: <AdditionalOpenEndedSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <OpenEndedSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: MessageRoundType) => <OpenEndedSlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: MessageRoundType) => <OpenEndedSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: MessageRoundType) => <OpenEndedSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <OpenEndedQuestionSvgIcon />,
		slideExample: OpenEndedSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Open-ended',
		nameTranslationKey: "OPEN_ENDED",
		alias: 'open ended',
		details: 'Collect responses up to 160 characters from your audience.',
		detailsTranslationKey: "Q_A_UPVOTING_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddQuestion,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: left; font-weight: 500;">${title || ``}</p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_OPEN_ENDED,
		addSlide: testIds.ADD_SLIDE_OPEN_ENDED,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.OpenEnded],
})

const UpvotingQuestion = (): SlideProperties => ({
	baseType: BaseTypes.MessageRound,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number): MessageRoundType => ({
		...MessageRoundModel({
			id,
			slideIndex,
			presentationId,
		}),
		type: SlideTypes.QA,
		hasUpvoting: 1,
		limitToSlide: 0,
	}),
	slideComponents: {
		slideEditorSettings: <UpvotingSettings />,
		slideEditorAdditionalOptions: <AdditionalUpvotingSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <QASlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: MessageRoundType) => <QASlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: MessageRoundType) => <QASlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: MessageRoundType) => <QASlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <QAQuestionSvgIcon />,
		slideExample: QASlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Q&A (upvoting)',
		nameTranslationKey: "Q_A_UPVOTING",
		alias: 'upvoting',
		details: 'Let your audience ask questions and rank their favorite ones by upvoting.',
		detailsTranslationKey: "Q_A_UPVOTING_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddQuestion,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: left; font-weight: 500;">${title || ``}</p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_Q_AND_A,
		addSlide: testIds.ADD_SLIDE_Q_AND_A,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.QA],
})

/* This question has the same implementation as MPC, just different wording/settings */
const PointsQuestion = (): SlideProperties => ({
	baseType: BaseTypes.Vote,
	// validation: (slide: VoteType) => validateMPCSlide(slide),
	emptySlide: (slideIndex: number, id: number, presentationId: number): VoteType => ({
		...VoteModel({
			id,
			presentationId,
			slideIndex
		}),
		type: SlideTypes.Points,
		nrOfVotes: 5,
		allowMultipleVotesPerAnswer: 1,
	}),
	slideComponents: {
		slideEditorSettings: <PointsSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <MultiplechoiceSlideSession slide={slide} />,
		slideEditorComponent: (slide: VoteType) => <PointsSlideEditor slide={slide} />,
		slidePlayPreviewComponent: (slide: VoteType) => <MultiplechoiceSlideSessionPreview slide={slide} />,
		slideEditorPreviewComponent: (slide: VoteType) => <PointsSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <PointsQuestionSvgIcon />,
		slideExample: PointsSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
		optionFontSize: SlideInputFontSize.Medium,
	},
	requiredFeature: Features.POINTS_QUESTION,
	text: {
		name: 'Points',
		nameTranslationKey: "POINTS",
		alias: 'points',
		details: 'Distribute 5, 10 or 15 points and see which answer options scores best.',
		detailsTranslationKey: "POINTS_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddQuestion,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: left; font-weight: 500;">${title || ``}</p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_POINTS,
		addSlide: testIds.ADD_SLIDE_POINTS,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Points],
})

const TitleAndTextSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		type: SlideTypes.Content,
	}),
	slideComponents: {
		slideEditorSettings: <ContentSlideSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <ContentSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: ContentSlideType) => <ContentSlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: ContentSlideType) => <ContentSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: ContentSlideType) => <ContentSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <ContentSlideSvgIcon />,
		slideExample: TitleAndTextSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
		contentFontSize: SlideInputFontSize.Small,
	},
	text: {
		name: 'Title and text',
		nameTranslationKey: "TITLE_AND_TEXT",
		alias: 'content_slide',
		details: 'Write a paragraph and share information.',
		detailsTranslationKey: "TITLE_AND_TEXT_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddTitle,
		contentPlaceholder: ContentPlaceholderText.AddText,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: left; font-weight: 500;">${title || ``}</p>`
		),
		getHtmlContentPlaceholder: (content?: string) => (
			`<p style="text-align: left; font-weight: 500;">${content || ``}</p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_TITLE_AND_TEXT,
		addSlide: testIds.ADD_SLIDE_TITLE_AND_TEXT,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
})


const CountdownContentSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		dynamicTimerLimit: 5,
		type: SlideTypes.Countdown,
	}),
	slideComponents: {
		slideEditorSettings: <QuizCountdownSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <QuizCountdownSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: ContentSlideType) => <QuizCountdownSlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: ContentSlideType) => <QuizCountdownSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: ContentSlideType) => <QuizCountdownSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <CountdownSlideSvgIcon />,
		slideExample: CountdownSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Countdown',
		nameTranslationKey: "COUNTDOWN",
		alias: 'countdown',
		details: 'Show a countdown on the screen',
		detailsTranslationKey: "COUNTDOWN_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.GetReadyForQuiz,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: center; font-weight: 500;"><strong>${title || ``}</strong></p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_COUNTDOWN_SLIDE,
		addSlide: testIds.ADD_SLIDE_COUNTDOWN,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
})

const TitleContentSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		type: SlideTypes.Title,
	}),
	slideComponents: {
		slideEditorSettings: <ContentSlideSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <TitleSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: ContentSlideType) => <TitleSlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: ContentSlideType) => <TitleSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: ContentSlideType) => <TitleSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <TitleSlideSvgIcon />,
		slideExample: TitleSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Title',
		nameTranslationKey: "TITLE",
		alias: 'title',
		details: 'Slide with main title.',
		detailsTranslationKey: "TITLE_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddTitle,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: left; font-weight: 500;">${title || ``}</p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_TITLE_SLIDE,
		addSlide: testIds.ADD_SLIDE_TITLE,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
})

const TitleAndSubtitleSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		type: SlideTypes.TitleAndSubtitle,
	}),
	slideComponents: {
		slideEditorSettings: <ContentSlideSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <TitleAndSubtitleSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: ContentSlideType) => <TitleAndSubtitleSlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: ContentSlideType) => <TitleAndSubtitleSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: ContentSlideType) => <TitleAndSubtitleSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <TitleAndSubtitleSlideSvgIcon />,
		slideExample: TitleAndSubtitleSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
		contentFontSize: SlideInputFontSize.Medium,
	},
	text: {
		name: 'Title and subtitle',
		nameTranslationKey: "TITLE_AND_SUBTITLE",
		alias: 'title_and_subtitle',
		details: 'Add a title and a smaller sentence after it.',
		detailsTranslationKey: "TITLE_AND_SUBTITLE_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddTitle,
		contentPlaceholder: ContentPlaceholderText.AddSubTitle,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: left; font-weight: 500;">${title || ``}</p>`
		),
		getHtmlContentPlaceholder: (content?: string) => (
			`<p style="text-align: left; font-weight: 500;">${content || ``}</p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_TITLE_AND_SUBTITLE_SLIDE,
		addSlide: testIds.ADD_SLIDE_TITLE_AND_SUBTITLE,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
})

const ImageContentSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		type: SlideTypes.Image,
	}),
	slideComponents: {
		slideEditorSettings: <ImageSlideSettings />,
		slidePlayComponent: (slide: ContentSlideType) => <ImageSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: ContentSlideType) => <ImageSlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: ContentSlideType) => <ImageSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: ContentSlideType) => <ImageSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <ImageSlideSvgIcon />,
		slideExample: ImageSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Image',
		nameTranslationKey: "IMAGE",
		alias: 'image',
		details: 'Support your message by adding an image to your presentation.',
		detailsTranslationKey: "IMAGE_SLIDE_DESCRIPTION",
		titlePlaceholder: '',
		getHtmlTitlePlaceholder: (title?: string) => (
			`${title || ``}`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_IMAGE_SLIDE,
		addSlide: testIds.ADD_SLIDE_IMAGE,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
})

const VideoContentSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		type: SlideTypes.Video,
	}),
	slideComponents: {
		slideEditorSettings: <VideoSlideSettings />,
		slidePlayComponent: (slide: ContentSlideType) => <VideoSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: ContentSlideType) => <VideoSlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: ContentSlideType) => <VideoSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: ContentSlideType) => <VideoSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <VideoSlideSvgIcon />,
		slideExample: VideoSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Video',
		nameTranslationKey: "VIDEO",
		alias: 'video',
		details: 'Add and play videos from YouTube.',
		detailsTranslationKey: "VIDEO_SLIDE_DESCRIPTION",
		titlePlaceholder: '',
		getHtmlTitlePlaceholder: (title?: string) => (
			`${title || ``}`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_VIDEO_SLIDE,
		addSlide: testIds.ADD_SLIDE_VIDEO,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
})

const QuoteContentSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		dynamicTimerLimit: 5,
		type: SlideTypes.Quote,
	}),
	slideComponents: {
		slideEditorSettings: <ContentSlideSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <QuoteSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: ContentSlideType) => <QuoteSlideSessionPreview slide={slide} />,
		slideEditorComponent: (slide: ContentSlideType) => <QuoteSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: ContentSlideType) => <QuoteSlideEditorPreview slide={slide} />,
	},
	slideAssets: {
		icon: <QuoteSlideSvgIcon />,
		slideExample: QuoteSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
		contentFontSize: SlideInputFontSize.Small,
	},
	text: {
		name: 'Quote',
		nameTranslationKey: "QUOTE",
		alias: 'quote',
		details: 'Highlight quotes in your presentation.',
		detailsTranslationKey: "QUOTE_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddQuote,
		contentPlaceholder: ContentPlaceholderText.AddQuoteAuthor,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: center; font-weight: 500;"><em><strong>${title || ``}</strong></em></p>`
		),
		getHtmlContentPlaceholder: (content?: string) => (
			`<p style="text-align: center; font-weight: 400;">${content || ``}</p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_QUOTE_SLIDE,
		addSlide: testIds.ADD_SLIDE_QUOTE,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
})

const TableSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		table: getPlaceholderTableData(),
		type: SlideTypes.Table,
	}),
	slideComponents: {
		slideEditorSettings: <TableSlideSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <TableSlideSession slide={slide} />,
		slidePlayPreviewComponent: (slide: ContentSlideType) => <TableSlideSession slide={slide} />,
		slideEditorComponent: (slide: ContentSlideType) => <TableSlideEditor slide={slide} />,
		slideEditorPreviewComponent: (slide: ContentSlideType) => <TableSlideSession slide={slide} />,
	},
	slideAssets: {
		icon: <TableSlideSvgIcon />,
		slideExample: TableSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Tables',
		nameTranslationKey: "TABLES",
		alias: 'table',
		details: 'Add tabular data to your presentation',
		detailsTranslationKey: "TABLE_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddTitle,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: center; font-weight: 500;"><strong>${title || ``}</strong></p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_TABLE_SLIDE,
		addSlide: testIds.ADD_SLIDE_TABLE,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
})


const LineGraphSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		table: getRandomPlaceholderChartData(),
		type: SlideTypes.LineGraph,
	}),
	slideComponents: {
		slideEditorSettings: <ContentSlideSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <GraphSlideSession graphType={AppChartType.Line} slide={slide} />,
		slidePlayPreviewComponent: (slide: ContentSlideType) => <GraphSlideSession graphType={AppChartType.Line} slide={slide} />,
		slideEditorComponent: (slide: ContentSlideType) => <GraphSlideEditor graphType={AppChartType.Line} slide={slide} />,
		slideEditorPreviewComponent: (slide: ContentSlideType) => <GraphSlideSession graphType={AppChartType.Line} slide={slide} />,
	},
	slideAssets: {
		icon: <LineGraphSlideSvgIcon />,
		slideExample: LineGraphSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Line Graph',
		nameTranslationKey: "LINE_GRAPH",
		alias: 'lineGraph',
		details: 'Create and show line graphs',
		detailsTranslationKey: "LINE_GRAPH_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddTitle,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: center; font-weight: 500;"><strong>${title || ``}</strong></p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_LINE_GRAPH_SLIDE,
		addSlide: testIds.ADD_SLIDE_LINE_GRAPH,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
})


const BarGraphSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	// validation: () => ({}) as SlideErrors,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		table: getRandomPlaceholderChartData(),
		type: SlideTypes.BarGraph,
	}),
	slideComponents: {
		slideEditorSettings: <ContentSlideSettings />,
		slidePlayComponent: (slide: PlaySlideType) => <GraphSlideSession graphType={AppChartType.Column} slide={slide} />,
		slidePlayPreviewComponent: (slide: ContentSlideType) => <GraphSlideSession graphType={AppChartType.Column} slide={slide} />,
		slideEditorComponent: (slide: ContentSlideType) => <GraphSlideEditor graphType={AppChartType.Column} slide={slide} />,
		slideEditorPreviewComponent: (slide: ContentSlideType) => <GraphSlideSession graphType={AppChartType.Column} slide={slide} />,
	},
	slideAssets: {
		icon: <BarGraphSlideSvgIcon />,
		slideExample: BarGraphSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Bar Graph',
		nameTranslationKey: "BAR_GRAPH",
		alias: 'barGraph',
		details: 'Create and show bar graphs',
		detailsTranslationKey: "BAR_GRAPH_SLIDE_DESCRIPTION",
		titlePlaceholder: TitlePlaceholderText.AddTitle,
		getHtmlTitlePlaceholder: (title?: string) => (
			`<p style="text-align: center; font-weight: 500;"><strong>${title || ``}</strong></p>`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_LINE_GRAPH_SLIDE,
		addSlide: testIds.ADD_SLIDE_LINE_GRAPH,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
})


const LeaderboardSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		type: SlideTypes.Leaderboard,
	}),
	slideComponents: {
		slideEditorSettings: <></>,
		slidePlayComponent: () => <></>,
		slidePlayPreviewComponent: () => <></>,
		slideEditorComponent: () => <></>,
		slideEditorPreviewComponent: () => <></>,
	},
	slideAssets: {
		icon: <LeaderboardSlideSvgIcon />,
		slideExample: BarGraphSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Leaderboard',
		nameTranslationKey: "LEADERBOARD",
		alias: 'leaderboard',
		details: 'Show a leaderboard to your audience',
		detailsTranslationKey: "LEADERBOARD_SLIDE_DESCRIPTION",
		titlePlaceholder: '',
		getHtmlTitlePlaceholder: (title?: string) => (
			`${title || ``}`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_LEADERBOARD_SLIDE,
		addSlide: testIds.ADD_SLIDE_LEADERBOARD,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
});

const PodiumSlide = (): SlideProperties => ({
	baseType: BaseTypes.Content,
	emptySlide: (slideIndex: number, id: number, presentationId: number) => ({
		...ContentSlideModel({
			id,
			slideIndex,
			presentationId,
		}),
		type: SlideTypes.Podium,
	}),
	slideComponents: {
		slideEditorSettings: <></>,
		slidePlayComponent: () => <></>,
		slidePlayPreviewComponent: () => <></>,
		slideEditorComponent: () => <></>,
		slideEditorPreviewComponent: () => <></>,
	},
	slideAssets: {
		icon: <PodiumSlideIconSvg />,
		slideExample: BarGraphSlideExampleImage,
	},
	slideSettings: {
		titleFontSize: SlideInputFontSize.Large,
	},
	text: {
		name: 'Podium',
		nameTranslationKey: "PODIUM",
		alias: 'podium',
		details: 'Show a podium to your audience',
		detailsTranslationKey: "PODIUM_SLIDE_DESCRIPTION",
		titlePlaceholder: '',
		getHtmlTitlePlaceholder: (title?: string) => (
			`${title || ``}`
		),
	},
	testIds: {
		changeSlide: testIds.CHANGE_SLIDE_TO_PODIUM_SLIDE,
		addSlide: testIds.ADD_SLIDE_PODIUM,
	},
	navigationStates: navigationStatesPerSlideType[SlideTypes.Content],
});

export {
	BarGraphSlide,
	CountdownContentSlide,
	ImageContentSlide, LeaderboardSlide, LineGraphSlide,
	MPCVote,
	OpenEndedQuestion, PodiumSlide, PointsQuestion,
	QuizVote,
	QuoteContentSlide,
	TableSlide,
	TitleAndSubtitleSlide,
	TitleAndTextSlide,
	TitleContentSlide,
	UpvotingQuestion,
	VideoContentSlide,
	WordCloudQuestion
};

