import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import { Grid } from '@mui/material';
import MediaComponent from 'Atomic/atoms/MediaComponent';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { SlideConsumerComponent } from 'Types/appTypes';
import { SlideType } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';

const PreviewVideoSlideShape = ({
    slide,
}: SlideConsumerComponent<SlideType>) => {

    const slideVideo = slide.videoURL;

    const { presentationStyle } = usePresentationStyles();

    return (
        <>
            <Grid className={classes.mediaAreaContainer}>
                {!Boolean(slideVideo) && (
                    <VideoLibraryOutlinedIcon
                        className={classes.videoIcon}
                        sx={{
                            color: presentationStyle?.textColor,
                        }}
                    />
                )}
            </Grid>
            {Boolean(slideVideo) && (
                <MediaComponent
                    interactive={true}
                    videoURL={slideVideo as string}
                />
            )}
        </>
    );
};

export default PreviewVideoSlideShape;