import CachedIcon from '@mui/icons-material/Cached';
import { Button, CircularProgress, InputBase, Typography } from '@mui/material';
import { Features, useFeatures } from 'Hooks/useFeatures';
import useMessageFilter from 'Hooks/useMessageFilter';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElPosition, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { AppState } from 'Types/appTypes';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

const ModeratorPanelOption = () => {

	const { isFeatureAvailable } = useFeatures();

	const hasModeratorPanelFeature = isFeatureAvailable(Features.MESSAGE_FILTER);

	const { enqueueSnackbar } = useSnackbar();

	const moderatorFieldRef = useRef<HTMLInputElement>(null);

	const userBrandingData = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).userBrandingData);

	const {
		currentSessionId,
    } = useSession();

	const {
		sharePanelLoading,
        moderatorSharingToken,
		fetchModeratorSharingToken,
		generateModeratorPanelToken,
	} = useMessageFilter();

	const generateToken = (e : MouseEvent) => {

		e.stopPropagation();

		generateModeratorPanelToken();
	}

	const copyText = (e : MouseEvent) => {

		e.stopPropagation();

		moderatorFieldRef.current && moderatorFieldRef.current.select();

		document.execCommand('copy');

		enqueueSnackbar(translatePlaceholder("RESPONSE_FILTER_LINK_COPIED"), { variant: 'info'});
	}

	useEffect(() => {

		if(Boolean(currentSessionId) && !Boolean(moderatorSharingToken)) {

			fetchModeratorSharingToken();
		}

	}, [currentSessionId]);

    const { translatePlaceholder } = useTranslations();

	return (
		<div className={classes.linkPanel}>
			<Typography variant="h3">
				{translatePlaceholder("LINK_TO_RESPONSE_FILTER")}
			</Typography>
			<InputBase
				inputProps={{
					ref: moderatorFieldRef,
					className: classes.input,
				}}
				onClick={(e: any) => copyText(e)}
				className={classes.moderatorLinkField}
				value={(Boolean(moderatorSharingToken) && !sharePanelLoading) ? `${userBrandingData?.dashboardUrl}/filter?access=${moderatorSharingToken}` : 'Loading...'}
				readOnly
				startAdornment={<>
					<Button onClick={(e: any) => generateToken(e)} className={classes.renewButton} {...renderingGAAttr(`${DataElAction.RefreshReviewResponseLink}${!hasModeratorPanelFeature ? '_limited' : ''}`, DataElPosition.None, `${window.location.pathname.split('/')[1]}`, DataElType.Button)}>
						{sharePanelLoading && <CircularProgress className={classes.shareFilterLoading} />}
						{!sharePanelLoading && <CachedIcon className={classes.renewIcon} />}
					</Button>
				</>}
				endAdornment={<>
					{Boolean(moderatorSharingToken) && <Button
						className={classes.copyButton}
						variant="contained"
						onClick={(e : any) => copyText(e)}
						{...renderingGAAttr(`${DataElAction.CopyReviewResponseLink}${!hasModeratorPanelFeature ? '_limited' : ''}`, DataElPosition.None, `${window.location.pathname.split('/')[1]}`, DataElType.Button)}
					>
						{translatePlaceholder("COPY")}
					</Button>}
				</>}
			/>
			<Typography variant="subtitle1">
				{translatePlaceholder("RESPONSE_FILTER_TIP")}
			</Typography>
		</div>
	);
};

export default ModeratorPanelOption;