import { BaseTypes } from 'Components/types/helpers/Slide';
import { useAppRouting } from 'Hooks/useAppRouting';
import { getSortedSlideKeysBySlideIndex } from 'Scripts/slideHelper';
import { PlayState } from 'Types/playTypes';
import { PresentationState, SlideProperties } from 'Types/presentationTypes';
import { isEmpty } from 'lodash';
import { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getPlaySlideProperties, getSlideProperties } from "../components/types/helpers/slideTypeHelper";

const useSlides = () => {

    const slides = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).slides);

    const playSlides = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).playSlides);

    const { presentationId, currentSlideKey } = useAppRouting();

    const slideKeys: number[] = getSortedSlideKeysBySlideIndex(slides);

    const currentSlide = slides[currentSlideKey] || {};

    const currentSlideProperties = useMemo(() => {

        if(!isEmpty(slides[currentSlideKey])) {

            return getSlideProperties(currentSlide);
        }

        return getPlaySlideProperties(playSlides[currentSlideKey]);

    }, [slides, playSlides, currentSlideKey])

    const [presentationContainsVotes, presentationContainsMessageRounds] = useMemo(() => {

        let hasVotes = false;

        let hasMsgs = false;

        slideKeys.forEach(slideKey => {

            const slideProperties: SlideProperties = getSlideProperties(slides[slideKey]);

            slideProperties.baseType === BaseTypes.Vote && (hasVotes = true);

            slideProperties.baseType === BaseTypes.MessageRound && (hasMsgs = true);
        })

        return [ hasVotes, hasMsgs ];

    }, [JSON.stringify(slideKeys)])

    const getOrderedArraySlides = ()=> {
        let slideSortedArray = Object.values(slides).sort((a, b) => a.slideIndex - b.slideIndex);
        return slideSortedArray;
    }

    const getCurrentSlideIndex = (orderedArraySlides)=> {
        let currentSlide = orderedArraySlides.find((currentSlide)=>{
            return currentSlide.id == currentSlideKey
        })
        return currentSlide.slideIndex
    }

    const getNewFocusSlide = ()=> {
        const orderedArraySlides = getOrderedArraySlides();
        const currentSlideKeyIndex = getCurrentSlideIndex(orderedArraySlides);
        const previousKey = currentSlideKeyIndex > 1 ? orderedArraySlides[orderedArraySlides.findIndex(slide => slide.slideIndex === currentSlideKeyIndex) - 1]?.id : null;
        const nextKey = currentSlideKeyIndex >= 1 && currentSlideKeyIndex <= orderedArraySlides.length - 1 ? orderedArraySlides[orderedArraySlides.findIndex(slide => slide.slideIndex === currentSlideKeyIndex) + 1]?.id : null;
        if(!nextKey){
            return Number(previousKey);
        } else {
            return  Number(nextKey);
        }
    }  
    
    return {
        slides,
        slideKeys,
        currentSlide,
        currentSlideKey,
        currentSlideProperties,
        presentationId,
        presentationContainsVotes,
        presentationContainsMessageRounds,
        getNewFocusSlide,
    }
}

export default useSlides;