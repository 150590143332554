import { TestIDs } from "./cypressTestIds";


export const parseTrackingValue = (trackingValue: string = '') => `${trackingValue}`.trim().toLocaleLowerCase().replace(/ /g, '_');

export const renderingGAAttr = (elAction: DataElAction | string, elPosition?: DataElPosition | string, elScreen?: DataElScreen, elType?: DataElType | string ) => {
    const trackingAttr = {
        "data-elaction": elAction && parseTrackingValue(elAction), // action and state (if is necessary) eg. change_language, play_music_true
        "data-elposition": elPosition ? parseTrackingValue(`${elPosition}`) : "undefined", // index item or section name eg. main option section || 0/1/2...
        "data-elscreen": elScreen && parseTrackingValue(elScreen), // page where it has been tracked eg. editor, settings
        "data-eltype": elType && parseTrackingValue(elType), // eg. button, link, toggle
    }

    return trackingAttr;
};

export interface TestIdAttributes {
    'data-testid'?: TestIDs | string | undefined;
}
export interface TrackingAttributes {
    'data-elaction'?: DataElAction | string | number;
    'data-elposition'?: DataElPosition | string | number;
    'data-elscreen'?: DataElScreen | string | number;
    'data-eltype'?: DataElType | string | number;
}

export enum DataElScreen {
    ContentHeader = "content_header",
    Editor = "editor",
    Library = "my_library",
    MultiLicense = "multi-license",
    Settings = "settings",
    InspirationTemplates = "inspiration_templates",
    Subscrition = "license_subscription",
    Billing = "license_billing",
    Account = "license_account",
    Results = "results",
    Manage = "team_manage",
    Play = "play",
    AddSlides = "add_slides",
    AddQuestions = "add_questions",
    AiContentCreator = "ai_content_creator",
    AddInspirationSlides = "add_inspiration_slides",
    Preview = "preview",
    EmptyState = "empty_state",
    Login = "login",
    PresentationSummary = "presentation_summary",
    EditorSlideSettings = "editor_slide_settings",
    AIStepper = "ai_stepper",
    QuizStepper = "quiz_stepper",
    DocUploadStepper = "doc_upload_stepper",
    RatingDialog = "rating_dialog",
    EditorMobile = "editor_mobile",
    EditorToolbar = "editor_toolbar",
    Dashboard = "dashboard",
    RecurringPresentations = "recurring_presentations",
    DataConnections = "data_connections",
    EditTemplateDialog = "edit_template_dialog",
    DataConnectionsOverview = "data_connections_overview",
    AccountSettings = "account_settings",
    DialogChangeEmail = "dialog_change_email",
}

export enum DataElType {
    Button = "button",
    Toggle = "toggle",
    Link = "link",
    Card = "card",
    Accordion = "accordion",
    Checkbox = "checkbox",
    Input = "input",
    Dropdown = "dropdown",
    Radio = "radio",
    Slider = "slider",
}

export enum DataElAction {
    CancelCancelation = "changed_mind_about_cancelation",
    ChangePresentationTitle = "change_presentation_title",
    AllowQuestionsBeforehand = "allow_questions_beforehand",
    RefreshReviewResponseLink = "refresh_review_response_link",
    CopyReviewResponseLink = "copy_review_response_link",
    AddOption = "add_option",
    UpdateOption = "update_option",
    SelectOpt = "select_option",
    DeleteOpt = "delete_option",
    ChangeQuestionText = "change_question_text",
    DisplayLeaderboard = "display_leaderboard",
    MaxResponseShown = "max_response_shown_on_presenter_screen",
    SelectMaxVotes = "select_max_votes",
    AllowMultipleVotes = "allow_multiple_votes",
    MaxResponsePerParticipant = "max_response_per_participant",
    PointsPerAnswer = "points_for_correct_answer",
    SelectTemplateStyle = "select_template_style",
    SelectSlideType = "select_slide_type",
    CreateYourOwn = "create_your_own",
    ShowResultsAs = "show_results_as",
    ShowResults = "show_results",
    SetTimeLimit = "set_time_limit",
    ParticipantsCanVoteOtherResponses = "participants_can_vote_other_responses",
    CountrySMSSelection = "country_sms_selection",
    IdentifyParticipant = "identify_participant",
    SMSResponse = "sms_response",
    AdvancedOpt = "advanced_options",
    ParticipantEdit = "participant_edit",
    ParticipantEmoji = "participant_emoji",
    ChangeLanguage = "change_language",
    ChangeAppLanguage = "change_app_language",
    LoginCodeEdit = "login_code_field_edit",
    SignInWithGoogle = "sign_in_with_google",
    SignInWithLinkedIn = "sign_in_with_linkedin",
    PlayMusic = "play_music",
    EvaluationSurvey = "evaluation_survey",
    SendEmailResults = "always_send_email_session_results",
    AutomaticRenewal = "automatic_renewal_to",
    ChangePlan = "change_my_plan",
    AboutPlan = "about_this_plan",
    UpgradePlan = "upgrade_plan",
    CloseRenewalDialog = "close_renewal_dialog",
    CancelRenewalReason = "cancel_renewal_reason",
    ConfirmCancelation = "confirm_cancelation",
    ShowResultsAsWordcloud = "show_results_as_wordcloud",
    DownloadWordcloudAsPNG = "download_wordcloud_as_png",
    ResendEmail = "resend_email_license",
    CancelRequest = "cancel_request_license",
    ContinueAsFree = "continue_as_free",
    UserNameInput = "add_user_name",
    UserLastNameInput = "add_user_last_name",
    UserDepartmentName = "add_user_department_name",
    UserEmail = "add_user_email",
    UserRole = "add_user_role",
    SaveAddUser = "save_and_add_user",
    QuizControlsFullScreen = "turn_full_screen",
    QuizControlsSound = "turn_sound",
    QuizControlsPause = "pause_presentation",
    QuizControlsPlay = "play_presentation",
    QuizControlsStop = "stop_presentation",
    QuizControlsForward = "go_forward_slide",
    QuizControlsBack = "go_back_slide",
    CloseWelcomeDialog = "close_welcome_dialog",
    ChangePaymentMethod = "change_payment_method",
    ChangeLayout = "change_layout_to",
    GetInspiration = "get_inspiration",
    ClosePanel = "close_panel",
    ResumeSession = "resume_session",
    ConfirmStopSession = "confirm_stop_session",
    ShowPreview = "show_preview",
    ClosePreview = "close_preview",
    ClosePreviewExplanation = "close_preview_explanation",
    AddMedia = "add_media",
    RemoveMedia = "remove_media",
    KeepWorkingOnSendsteps = "continue_working",
    SharePresentation = "share_presentation",
    UnSharePresentation = "unshare_presentation",
    SeeAllTemplates = "see_all_inspiration_templates",
    ClaimTemplatePrefix = "claim_template",
    SetQuizCountdown = "set_quiz_countdown_value",
    SearchButton = "search_button",
    ShowSendsteps = "show_sendsteps_details",
    AddQuestion = "add_question",
    AddQuestionTab = "add_question_tab",
    AddSlideTab = "add_slide_tab",
    AddSlide = "add_slide",
    StartSendsteps = "start_sendsteps",
    PresentationPreviewNavigateNext = "presentation_preview_navigate_next",
    PresentationPreviewNavigatePrevious = "presentation_preview_navigate_previous",
    CloseSummary = "close_presentation_summary",
    ConfirmRedirectFromDashboard = "confirm_redirect_from_dashboard",
    ImportPowerpoint = "import_powerpoint",
    ExportToPowerpoint = "export_to_powerpoint",
    SlideTabLayout = "slide_tab_layout",
    SlideTabConfiguration = "slide_tab_configuration",
    GenerateAIPresentation = "generate_ai_presentation",
    GenerateWithAi = "generate_with_ai",
    EditAIStep = "edit_ai_step",
    EditAIStepIcon = "edit_ai_step_icon",
    ConfirmAIStep = "confirm_ai_step",
    FitImage = "fit_image",
    FillImage = "fill_image",
    FixAspectRatio = "fix_aspect_ratio",
    UseOwnAITitle = "use_own_ai_title",
    TourNextStep = "tour_next_step",
    TryForFree = "generate_ai_try_for_free",
    Donate = "donate",
    CloseAiBanner = "close_ai_banner",
    RatingRemindLater = "rating_remind_later",
    RatingRate = "rating_rate",
    ReviewAISlides = "review_ai_slides",
    CloseAIPresentationReadyDialog = "close_ai_presentation_ready_dialog",
    ConfirmAIInput = "confirm_ai_input",
    ConfirmAILanguage = "confirm_ai_language",
    ConfirmAIDocument = "confirm_ai_document",
    ConfirmAIAudience = "confirm_ai_audience",
    ConfirmAIToneOfVoice = "confirm_ai_tone_of_voice",
    ConfirmAIWriteFor = "confirm_ai_write_for",
    ConfirmAIPresentationConfiguration = "confirm_ai_presentation_configuration",
    ConfirmAIQuizConfiguration = "confirm_ai_quiz_configuration",
    ConfirmAITitle = "confirm_ai_title",
    ConfirmAIGenerate = "confirm_ai_generate",
    GenerateMoreAI = "generate_more_ai",
    FillImageArea = "fill_image_area",
    FitToImageArea = "fit_to_image_area",
    UploadDocument = "upload_document",
    UploadDocumentViaStepper = "upload_document_via_stepper",
    BrowseDocumentUpload = "browse_document_upload",
    CloseDocumentUpload = "close_document_upload",
    SelectQuiz = "select_quiz",
    SelectPresentation = "select_presentation",
    AIToolbar = "ai_editor_toolbar",
    AIRewrite = "ai_rewrite",
    AIRewriteExtend = "ai_rewrite_exend",
    AIRewriteReduce = "ai_rewrite_reduce",
    AIAdjustToneMenu = "ai_adjust_tone_menu",
    GeneratePresentationFromOutline = "generate_presentation_from_outline",
    EditOutlineItem = "edit_outline_item",
    DeleteOutlineItem = "delete_outline_item",
    ConfirmOutlineTitle = "confirm_outline_title",
    RewriteOutlineItem = "rewrite_outline_item",
    UndoOutlineChange = "undo_outline_change",
    RedoOutlineChange = "redo_outline_change",
    OpenFeedbackDialog = "open_feedback_dialog",
    CloseFeedbackDialog = "close_feedback_dialog",
    AIContentCreatorTab = "ai_content_creator_tab",
    AiContentCreatorCard = "ai_content_creator_card",
    AiContentCreatorButton = "ai_content_creator_button",
    AiContentCreatorInput = "ai_content_creator_input",
    AiContentCreatorToSelectSlidesStep = "ai_content_creator_to_select_slides_step",
    AiContentCreatorToSelectTypeStep = "ai_content_creator_to_select_type_step",
    AiContentCreatorBackToSelectTypeStep = "ai_content_creator_back_to_select_type_step",
    AiContentCreatorBackToKeywordsStep = "ai_content_creator_back_to_keywords_step",
    AiContentCreatorNextTemplatePage = "ai_content_creator_next_template_page",
    AiContentCreatorPreviousTemplatePage = "ai_content_creator_previous_template_page",
    AiContentCreatorAddToPresentation = "ai_content_creator_add_to_presentation",
    AiContentCreatorSelectSlide = "ai_content_creator_select_slide",
    AiContentCreatorSelectType = "ai_content_creator_select_type",
    FeatureAutoFillPresentationDataTryNowButton = "feature_autofill_presentation_data_try_now_button",
    FeatureAutoFillPresentationDataIWantThisButton = "feature_autofill_presentation_data_i_want_this_button",
    FeatureAutoFillPresentationDataNotForMeButton = "feature_autofill_presentation_data_not_for_me_button",
    FeatureAutoFillPresentationDataCloseDialogButton = "feature_autofill_presentation_data_close_dialog_button",
    StartFromScratch = "start_from_scratch",
    CloseThanksForStayingPopup = "close_thanks_for_staying_popup",
    OffboardingAcceptDiscount = "offboarding_accept_discount",
    CloseWebinarBanner = "close_webinar_banner",
    OpenWebinarLink = "open_webinar_link",
    OneOnOneMeetingLink = "one_on_one_meeting_link",
    ToggleSpeakerNotes = "toggle_speaker_notes",
    ToggleAutoMovement = "toggle_auto_movement",
    ToggleQuizStrictModeOn = "toggle_quiz_strict_mode_on",
    ToggleQuizStrictModeOff = "toggle_quiz_strict_mode_off",
    RevertTemplateChanges = "revert_template_changes",
    CloseDialogAndRevertTemplateChanges = "close_dialog_and_revert_template_changes",
    ContinueEditingTemplate = "continue_editing_template",
    AddGoogleAnalyticsDataConnection = 'add_ga4_data_connection',
    ResendLoginCode = 'resend_login_code',
    CustomDeckDesignButton = 'custom_deck_design',
    CustomDeckDesignNotInterested = "custom_deck_design_not_interested",
    CustomDeckDesignInterested = "custom_deck_design_interested",
    CustomDeckDesignDialogClose = 'custom_deck_design_dialog_close',
    CustomDeckDesignDialogInteresedClose = 'custom_deck_design_dialog_interested_close',
    ChangeEmailEdit = "change_email_edit",
    ChangeEmailCancel = "change_email_cancel",
    ChangeEmailSave = "change_email_save",
    CloseDialog = "close_dialog",
    ChangeEmail = "change_email",
    ChangeDepartment="change_department",
    ChangeLastName="change_last_name",
    ChangeFirstName="change_first_name",
}

export enum DataElPosition {
    NavigationHeaderEnd = "navigation_header_end",
    BellowSlide = "below_slide",
    SettingsContainer = "settings_container",
    ContainterHeader = "settings_container_header",
    BillingInvoicing = "billing_invoicing_settings",
    MySubscription = "my_subscription_section",
    BillingInvoicingDialog = "billing_invoicing_dialog",
    Bottom = "bottom",
    Top = "top",
    None = "",
    HideAdvancedSection = "hide_advanced_section",
    ReviewSection = "review_section",
    MainOptSection = "main_options_section",
    EmailPreferences = "email_preferences",
    AddUserDialog = "add_user_dialog",
    RightHiddenMenu = "right_hidden_menu",
    Dialog = "popup",
    MediaArea = "media_field",
    MediaButtonGroup = "media_button_group",
    FeaturePopup = "feature_popup",
    TemplatesBlock = "inspiration_template_block",
    LeftSlideDrawer = "left_drawer_miniature_slides",
    LoginDialog = "login_dialog",
    BillingInformation = "billing_information",
    AiBanner = "ai_banner",
    ClaimLimitedTimeOffer = "claim_limited_time_offer",
    WebinarBanner = "webinar_banner",
}

export enum DataEventNames {
    AutofilledPresentationInterest = 'autofilled_presentation_interest',
}