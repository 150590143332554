import { Typography } from '@mui/material';
import FeatureWrapper from 'Atomic/atoms/FeatureWrapper/FeatureWrapper';
import OnOffSwitch from 'Components/beta/common/buttons/switch/OnOffSwitch';
import Caption from 'Components/beta/common/caption/Caption';
import SummaryOption from 'Components/beta/common/summary/option/SummaryOption';
import { Features, useFeatures } from 'Hooks/useFeatures';
import usePlaySettings from 'Hooks/usePlaySettings';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { PresentationState } from 'Types/presentationTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';


const PlayMusicToggle = () => {


	const {
		playMusic,
		toggleMusic,
	} = usePlaySettings();

	const {
		isFeatureAvailable
	} = useFeatures();

	const {
		userSessionInfo
	} = useSession();

	const { translatePlaceholder } = useTranslations();

	const presentationLoading = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).presentationLoading);

	const hasMusicFeature = isFeatureAvailable(Features.BACKGROUND_MUSIC);

	const toggleSetting = () => {

		toggleMusic();
	}

	return (
		<SummaryOption
			loading={!userSessionInfo || presentationLoading}
			leftItem={<div>
				<Typography variant="body2" >
					{!hasMusicFeature && "💎"} {translatePlaceholder("PLAY_MUSIC")}
				</Typography>
				<Caption title={translatePlaceholder("PLAY_MUSIC_EXPLANATION")} />
			</div>
			}
			rightItem={(
				<FeatureWrapper
					data-elscreen={DataElScreen.Settings}
					feature={Features.BACKGROUND_MUSIC}>
					<OnOffSwitch
						checked={playMusic}
						onChange={() => toggleSetting()}
					/>
				</FeatureWrapper>
			)}
			trackingAttr={renderingGAAttr(`${DataElAction.PlayMusic}_${playMusic}${!hasMusicFeature ? '_limited' : ''}`, DataElPosition.MainOptSection, DataElScreen.Settings, DataElType.Toggle)}
		/>
	);
};

export default PlayMusicToggle;