import { Divider, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import SlideSettingButton from 'Components/beta/common/buttons/settings/slide/SlideSettingButton';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import React, { useEffect, useState } from 'react';
import { classes } from './style.css';

const selectablePoints = [
    5, 10, 15
];

const PointsPerAnswerSetting = () => {

    const {
		currentSlide,
		currentSlideKey,
	} = useSlides();

	const {
        setSlideSetting,
	} = useSlideActions();

	const { translatePlaceholder } = useTranslations();

	const [placeholderVoteAmount, setPlaceholderVoteAmount] = useState<number>(0);

	const setAmountOfVotesAllowed = (amount: number) => {

		setSlideSetting('nrOfVotes', amount, currentSlideKey);
	}

	useEffect(() => {

		setPlaceholderVoteAmount(currentSlide.nrOfVotes);

	}, [currentSlide.nrOfVotes])


	const theme = useTheme();

	return (
		<div>
			<div className={classes.voteAmountContainer}>
				<Typography variant="h4" className={classes.voteAmountText}>
					{translatePlaceholder("MAXIMUM_POINTS")}
				</Typography>
				<div className={classes.buttonBox}>
					{selectablePoints.map(voteAmount => (
						<Box key={voteAmount} className={classes.buttonContainer} sx={{
							minWidth: `calc(${100 / (selectablePoints.length)}% - ${theme.spacing(1 / 4)})`,
						}}>
							<SlideSettingButton
                                data-testid={`${testIds.SLIDE_OPTION_POINTS_QUESTION}-${voteAmount}`}
								isActive={placeholderVoteAmount === voteAmount}
								onClick={() => setAmountOfVotesAllowed(voteAmount)}
								value={voteAmount}
							/>
						</Box>
					))}
				</div>
			</div>
			<Divider />
		</div>
	);
}

export default PointsPerAnswerSetting;